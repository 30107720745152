import { useCallback } from "react"
import { IAlertType } from "../../application/ui"
import { Bounce, toast } from "react-toastify"

export const useUiService = () => {
  const setAlert = useCallback((data: {
    type: IAlertType,
    message: string
  }) => {
    toast[data.type](data.message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Bounce,
      });
  }, [])
  return {
    setAlert
  }
}