import { useCallback } from "react"
import { useAxiosInstance } from "../axios-instance"
import { IKuroroTeam, IKuroroTeamSlot } from "../../application/team-builder"
import { useAppSelector } from "../../hooks"

interface ITeamApiPostModel {
  "nickname": string
  "name": string
  "teamKuroro": {
    "kuroroId": string
    "moves": number[]
    "bonusStats": {
      "hitPoints": number,
      "attack": number,
      "defense": number,
      "magicAttack": number,
      "magicDefense": number,
      "speed": number
    }
  }[]

}

interface ITeamApiModel {
  "teamId": number,
  "userNickname": string,
  "name": string,
  "kuroros": {
    "kuroroId": string,
    "moves": number[],
    "bonusStats": {
      "id": number,
      "hitPoints": number,
      "attack": number,
      "defense": number,
      "magicAttack": number,
      "magicDefense": number,
      "speed": number
    }
  }[]
}


export const useTeamApi = () => {
  const { axiosPrivateInstance } = useAxiosInstance()
  const moves = useAppSelector((state) => state.kuroroWIki.moves)
  const kuroros = useAppSelector((state) => state.kuroroWIki.kuroros)

  const getTeamsByUserNicknameApi = useCallback(async (data: {
    nickname: string
  }) => {
    try {
      const result: IKuroroTeam[] = []
      const response = await axiosPrivateInstance.get<ITeamApiModel[]>(`/team/user/nickname/${data.nickname}`)
      for (let i = 0; i < response.data.length; i++) {
        const team = response.data[i]
        const slots: IKuroroTeamSlot[] = []
        for (let k = 0; k < team.kuroros.length; k++) {
          const slot = team.kuroros[k]
          const kuroro = kuroros.find((item) => {
            return item.id === slot.kuroroId.toString()
          })

          slots.push({
            id: Math.random(),
            bonusStats: {
              atk: slot.bonusStats.attack,
              def: slot.bonusStats.defense,
              hp: slot.bonusStats.hitPoints,
              matk: slot.bonusStats.magicAttack,
              mdef: slot.bonusStats.magicDefense,
              speed: slot.bonusStats.speed
            },
            kuroro: kuroro,
            moves: moves.filter((item) => {
              return slot.moves.includes(Number(item.id))
            })
          })
        }
        result.push({
          id: team.teamId.toString(),
          name: team.name,
          userNickname: team.userNickname.toString(),
          slots: slots
        })
      }
      return result
    } catch (err: any) {
      throw new Error(err.response.data.message)
    }

  }, [
    axiosPrivateInstance,
    moves,
    kuroros
  ])

  const deleteTeamApi = useCallback(async (data: {
    teamId: string
  }) => {
    try {
      await axiosPrivateInstance.delete<ITeamApiModel>(`/team/${data.teamId}`)
    } catch (err: any) {
      throw new Error(err.response.data.message)
    }
  }, [
    axiosPrivateInstance
  ])

  const createTeamApi = useCallback(async (data: {
    team: IKuroroTeam,
    nickname: string
  }) => {
    try {
      const castedCata: ITeamApiPostModel = {
        "name": data.team.name,
        nickname: data.nickname,
        teamKuroro: data.team.slots.map((item) => {
          return {
            bonusStats: {
              attack: item.bonusStats.atk,
              defense: item.bonusStats.def,
              hitPoints: item.bonusStats.hp,
              magicAttack: item.bonusStats.matk,
              magicDefense: item.bonusStats.mdef,
              speed: item.bonusStats.speed
            },
            kuroroId: item.kuroro?.id ?? '',
            moves: item.moves.map((move) => {
              return Number(move.id)
            })
          }
        })
      }
      const response = await axiosPrivateInstance.post<ITeamApiModel>('/team', castedCata)
      return response.data
    } catch (err: any) {
      throw new Error(err.response.data.message)
    }
  }, [
    axiosPrivateInstance
  ])


  const updateTeamApi = useCallback(async (data: {
    team: IKuroroTeam,
    nickname: string
  }) => {
    try {
      const castedCata: ITeamApiPostModel = {
        "name": data.team.name,
        nickname: data.nickname,
        teamKuroro: data.team.slots.map((item) => {
          return {
            bonusStats: {
              attack: item.bonusStats.atk,
              defense: item.bonusStats.def,
              hitPoints: item.bonusStats.hp,
              magicAttack: item.bonusStats.matk,
              magicDefense: item.bonusStats.mdef,
              speed: item.bonusStats.speed
            },
            kuroroId: item.kuroro?.id ?? '',
            moves: item.moves.map((move) => {
              return Number(move.id)
            })
          }
        })
      }
      console.log(castedCata)
      const response = await axiosPrivateInstance.put<ITeamApiModel>(`/team/${data.team.id}`, castedCata)
      return response.data
    } catch (err: any) {
      throw new Error(err.response.data.message)
    }
  }, [
    axiosPrivateInstance
  ])

  const getTeamByIdApi = useCallback(async (data: {
    teamId: string
  }): Promise<IKuroroTeam> => {
    try {
      const response = await axiosPrivateInstance.get<ITeamApiModel>(`/team/${data.teamId}`)
      return {
        id: response.data.teamId.toString(),
        name: response.data.name,
        slots: response.data.kuroros.map((slot) => {
          const kuroro = kuroros.find((item) => {
            return item.id === slot.kuroroId
          })
          return {
            id: Math.random(),
            bonusStats: {
              atk: slot.bonusStats.attack,
              def: slot.bonusStats.defense,
              hp: slot.bonusStats.hitPoints,
              matk: slot.bonusStats.magicAttack,
              mdef: slot.bonusStats.magicDefense,
              speed: slot.bonusStats.speed
            },
            moves: moves.filter((move) => {
              return slot.moves.includes(Number(move.id))
            }),
            kuroro: kuroro
          }
        }),
        userNickname: response.data.userNickname
      }
    } catch (err: any) {
      throw new Error(err.response.data.message)
    }
  }, [
    axiosPrivateInstance,
    moves,
    kuroros
  ])

  return {
    createTeamApi,
    getTeamsByUserNicknameApi,
    deleteTeamApi,
    updateTeamApi,
    getTeamByIdApi
  }

}