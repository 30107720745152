import { Box, Button, Container, Grid, Typography } from "@mui/material"
import ReactGA from "react-ga4";

export const PlayKuroroBeastsHomePage = () => {
  return <>
    <div style={{
      display: 'flex'
    }}>

      <Container component="main" maxWidth="md">
        <Box p={3}>
          <Grid container justifyContent={'center'} spacing={3}>
            <Grid item xs={12}>
              <Box sx={{
                display: 'flex',
                justifyContent: 'center'
              }}>
                <Button onClick={() => {
                  ReactGA.event({
                    category: "referal",
                    action: "referal",
                  });
                  window.open('https://kuroro.com/auth?referralCode=7LNenoRd', '_blank')?.focus()
                }} style={{
                  padding: '30px'
                }} variant="contained" color="secondary">
                  <Typography variant="h5" fontWeight={"bold"}>
                    Click Here To Play Kuroro Beasts Right Now!
                  </Typography>
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" color="secondary">
                What is Kuroro Beasts?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="white" style={{
                margin: '15px 0'
              }}>
                Kuroro Beasts is a gaming ecosystem that takes place in the Kuroro Archipelago, a place lost in time, filled with mysteries and stories to be unveiled.
              </Typography>
              <Typography>
                The archipelago is inhabited by mystical Beasts which are one with the Elements of the islands, harnessing their properties to become extremely powerful, and sometimes dangerous. Islanders have discovered these Beasts can be captured, trained and evolved to aid them in their adventures to unravel the mysteries of Kuroro and its origins.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" color="secondary">
                Can I play it now?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{
                marginBottom: '15px'
              }}>
                Kuroro is now playable on Kuroro Beast Brawl: Enter the arena of Kuroro Beast Brawl, an electrifying PvP turn-based battle game that brings a subtle sense of anticipation with every move. It's a chess-like dance where players weave together strategies, methodically choosing their actions and launching attacks against their adversary's Beasts, each equipped with a distinct set of combat abilities. Strategically outmaneuver your opponents, and secure victory by knocking out all of their Beasts, asserting your dominance in the Kuroro Beast Brawl.
              </Typography>
              <Typography style={{
                marginBottom: '15px'
              }}>
                Beast Brawl offers a free, rotating roster of Beasts each week. This allows players to experiment with fresh Beasts and diverse team formations at zero cost, and also injects an element of surprise into the gameplay, creating an ever-evolving battlefield that keeps players engaged and eager to master new strategies. Customize your Beast’s ability pool and skill points into different stats, creating limitless opportunities for pre-game strategy. Climb the ranked ladder, facing opponents that rival your level of skill as you compete for monthly prizes.
              </Typography>
              <Typography >
                Owning Beasts from any of our NFT collections grants access to even more Beasts, enriching your arsenal with more robust and versatile options.
              </Typography>

            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" color="secondary">
                How to Participate in the Airdrop?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{
                marginBottom: '15px'
              }}>
                Kuroro is now playable on Kuroro Beast Brawl: Enter the arena of Kuroro Beast Brawl, an electrifying PvP turn-based battle game that brings a subtle sense of anticipation with every move. It's a chess-like dance where players weave together strategies, methodically choosing their actions and launching attacks against their adversary's Beasts, each equipped with a distinct set of combat abilities. Strategically outmaneuver your opponents, and secure victory by knocking out all of their Beasts, asserting your dominance in the Kuroro Beast Brawl.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>

    </div>
  </>
}