type ILocalStorageKey = 'moves' | 'kuroros' | 'token'
export const useLocalStorageService = () => {
  const setItem = (data: {
    key: ILocalStorageKey,
    value: string
  }) => {
    window.localStorage.setItem(data.key, data.value)
  }
  const getItem = (data: {
    key: ILocalStorageKey
  }) => {
    return window.localStorage.getItem(data.key)
  }

  const deleteItem = (data: {
    key: ILocalStorageKey
  }) => {
    window.localStorage.removeItem(data.key)
  }
  return {
    getItem,
    setItem,
    deleteItem
  }
}