import { Dialog, Button, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery, useTheme, TextField, Grid, Box } from "@mui/material"
import React from "react";
import { useAppSelector } from "../hooks";
import { useTeamBuilderService } from "../services/team-builder";

export const SettingsDialog = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // const updateTeamSettingsForm = useAppSelector((state) => state.teamBuilder.updateTeamSettingsForm)
  const {
    setUpdateTeamSettingsName,
    setOpenUpdateTeamSettings,
    updateTeam
  } = useTeamBuilderService()
  const selectedTeam = useAppSelector((state) => state.teamBuilder.selectedTeam)
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    updateTeam()
  };
  const openUpdateTeamSettings = useAppSelector((state) => state.teamBuilder.openUpdateTeamSettings)

  return <>
    <Dialog
      fullScreen={fullScreen}
      open={openUpdateTeamSettings}
      onClose={() => {
        setOpenUpdateTeamSettings({
          open: false
        })
      }}
      aria-labelledby="responsive-dialog-title"
    >
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>

        <DialogTitle id="responsive-dialog-title">
          {"Update Team Settings"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {"This dialog allows you to update your customized settings. Please review and make necessary changes as desired."}
          </DialogContentText>
          <Grid container spacing={3} sx={{
            mt: '20px'
          }}>
            <Grid item xs={12}>
              <TextField
                label="Team name"
                fullWidth
                value={selectedTeam.name}
                onChange={(e) => {
                  setUpdateTeamSettingsName({
                    name: e.target.value
                  })
                }}
                error={!Boolean(selectedTeam.name)}
                helperText={
                  !Boolean(selectedTeam.name) && "Team name is a required field"
                }
                autoComplete="given-name"
                name="team-name"
                id="team-name"
                placeholder='Type team name'
                margin="normal"
                required
                autoFocus
              />
            </Grid>
            {/* <Grid item xs={12}>
              <FormControlLabel control={<Checkbox onChange={(e) => {
                setUpdateTeamSettingsMadeItPublic({
                  madeItPublic: e.target.checked
                })
              }} checked={updateTeamSettingsForm.fields.madeItPublic} />} label="Public" />
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus color="secondary" onClick={() => {
            setOpenUpdateTeamSettings({
              open: false
            })
          }}>
            Cancel
          </Button>
          <Button type="submit" color="secondary" onClick={() => {

          }} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Box>
    </Dialog>

  </>
}