import { useCallback } from "react"
import { useUserApi } from "../../infra/user"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { setSignUpFormConfirmEmailState, setSignUpFormConfirmPasswordState, setSignUpFormDeliveredState, setSignUpFormEmailState, setSignUpFormErrorsState, setSignUpFormNicknameState, setSignUpFormPasswordState, setSignUpFormTouchedState, validateSignUpForm } from "../../application/user"
import { useUiService } from "../ui"
import { sha512 } from "js-sha512"
import { useTeamBuilderService } from "../team-builder"
export const useUserService = () => {

  const dispatch = useAppDispatch()

  const signUpForm = useAppSelector((state) => state.user.signUpForm)

  const { setAlert } = useUiService()
  const selectedTeam = useAppSelector((state) => state.teamBuilder.selectedTeam)
  const { createTeam } = useTeamBuilderService()
  const { createUserApi } = useUserApi()
  const setSignUpFormNickname = useCallback((data: {
    nickname: string
  }) => {
    dispatch(setSignUpFormNicknameState({
      nickname: data.nickname
    }))
  }, [dispatch])

  const setSignUpFormEmail = useCallback((data: {
    email: string
  }) => {
    dispatch(setSignUpFormEmailState({
      email: data.email
    }))
  }, [dispatch])

  const setSignUpFormConfirmEmail = useCallback((data: {
    confirmEmail: string
  }) => {
    dispatch(setSignUpFormConfirmEmailState({
      confirmEmail: data.confirmEmail
    }))
  }, [dispatch])

  const setSignUpFormPassword = useCallback((data: {
    password: string
  }) => {
    dispatch(setSignUpFormPasswordState({
      password: data.password
    }))
  }, [dispatch])

  const setSignUpFormConfirmPassword = useCallback((data: {
    confirmPassword: string
  }) => {
    dispatch(setSignUpFormConfirmPasswordState({
      confirmPassword: data.confirmPassword
    }))
  }, [dispatch])


  const createUser = useCallback(async () => {
    const errors = validateSignUpForm(signUpForm.fields)
    if (errors.length > 0) {
      dispatch(setSignUpFormTouchedState({
        touched: true
      }))
      dispatch(setSignUpFormErrorsState({
        errors: errors
      }))
      return
    } else {
      return createUserApi({
        email: signUpForm.fields.email,
        nickname: signUpForm.fields.nickname,
        password: sha512(signUpForm.fields.password)
      }).then((response) => {
        if (selectedTeam.slots.length > 0) {
          createTeam({
            userId: 'new',
            nickname: signUpForm.fields.nickname
          })
        }
        dispatch(setSignUpFormDeliveredState({
          delivered: true
        }))
        setAlert({
          message: 'User created successfully',
          type: 'success'
        })
      }).catch((err) => {
        setAlert({
          message: err?.message ?? 'Unknown Error',
          type: 'error'
        })
      })
    }
  }, [
    createTeam,
    createUserApi,
    dispatch,
    selectedTeam,
    setAlert,
    signUpForm.fields
  ])

  return {
    setSignUpFormNickname,
    setSignUpFormEmail,
    setSignUpFormPassword,
    setSignUpFormConfirmPassword,
    createUser,
    setSignUpFormConfirmEmail
  }
}