import { Outlet } from "react-router-dom"
import './PrivateSectionLayout.css'
import { Box } from "@mui/material"
import React, { useEffect } from "react"
import { useAuthService } from "../../services/auth"

import { Header } from "./header/Header"


export const WorkspaceSectionLayout = () => {

  const { setupSection } = useAuthService()

  useEffect(() => {
    setupSection()
  }, [setupSection])

  return <>
    <Header />
    <Box p={3}>
      <Outlet />
    </Box>
  </>
}