import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Copyright } from '../sign-up';
import { useAuthService } from '../../../services/auth';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../../hooks';
import { CircularProgress } from '@mui/material';

export function PasswordResetPage() {
  const resetPasswordForm = useAppSelector((state) => state.auth.resetPasswordForm)
  const { resetPassword, setResetPasswordPassword, setResetPasswordConfirmPassword } = useAuthService()
  const [showForm, setShowForm] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true)
    resetPassword({
      token: searchParams.get('token') ?? ''
    }).then(() => {
      setLoading(false)
    })
  };
  const [searchParams] = useSearchParams()
  React.useEffect(() => {
    if (searchParams.get('token')) {
      setShowForm(true)
    }
  }, [
    searchParams
  ])

  return (
    <>
      {
        showForm ? (
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Password Reset
              </Typography>
              <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  label="New Password"
                  name="password"
                  value={resetPasswordForm.fields.password}
                  onChange={(e) => {
                    setResetPasswordPassword({
                      password: e.target.value
                    })
                  }}
                  error={Boolean(resetPasswordForm.touched && resetPasswordForm.errors.find(item => item.key === 'password'))}
                  helperText={
                    resetPasswordForm.errors.find(item => item.key === 'password')?.message
                  }
                  autoFocus
                  type="password"
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="confirmPassword"
                  label="New Password"
                  name="password"
                  value={resetPasswordForm.fields.confirmPassword}
                  onChange={(e) => {
                    setResetPasswordConfirmPassword({
                      confirmPassword: e.target.value
                    })
                  }}
                  error={Boolean(resetPasswordForm.touched && resetPasswordForm.errors.find(item => item.key === 'confirmPassword'))}
                  helperText={
                    resetPasswordForm.errors.find(item => item.key === 'confirmPassword')?.message
                  }
                  autoFocus
                  type="password"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={loading}
                >
                  {
                    loading ? (
                      <CircularProgress />
                    ) : " Reset Password"
                  }
                </Button>
              </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
          </Container>
        ) : null
      }
    </>
  );
}