import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

import Typography from '@mui/material/Typography';

import { setAction, setOpenTypesChartState, setSelectedSlotsToPlayState, setViewSelectedToPlayState } from '../../../application/team-builder';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { KuroroSlot } from '../../../components/KuroroSlot';
import { AppBar, Avatar, Box, Chip, Container, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, Slide, Switch, Toolbar, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { KuroroPickupList } from '../../../components/KuroroPickupList';
import { SelectedKuroro, atkTypeInfoMap, kuroroTypeColorMap } from '../../../components/SelectedKuroro';
import CloseIcon from '@mui/icons-material/Close';

import InfoIcon from '@mui/icons-material/Info';

import { IKuroroType } from '../../../types/IKuroroType';
import TableChartIcon from '@mui/icons-material/TableChart';
import './Table.css'
import { useTeamBuilderService } from '../../../services/team-builder';
import { useNavigate, useParams } from 'react-router-dom';
import { TransitionProps } from '@mui/material/transitions';
import { NewTeamDialog } from './NewTeamDialog';
import { TypesDoubleEntryTable } from '../../../components/TypesDoubleEntryTable';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function EditTeamDialog(props: {
}) {
  const navigate = useNavigate()
  const selectedTeam = useAppSelector((state) => state.teamBuilder.selectedTeam)
  const selectedSlotsToPlay = useAppSelector((state) => state.teamBuilder.selectedSlotsToPlay)
  const openTypesChart = useAppSelector((state) => state.teamBuilder.openTypesChart)
  const selectedSlot = useAppSelector((state) => state.teamBuilder.selectedSlot)
  const viewSelectedToPlay = useAppSelector((state) => state.teamBuilder.viewSelectedToPlay)
  const dispatch = useAppDispatch()

  const { updateTeam, getTeamById, saveSlot, resetSlot, setOpenNewTeamDialog } = useTeamBuilderService()

  const theme = useTheme()
  const upMd = useMediaQuery(theme.breakpoints.up('md'));
  const { teamId } = useParams()

  React.useEffect(() => {
    if (teamId) {
      getTeamById({
        teamId: teamId
      })
    }

  }, [teamId, getTeamById])

  return (
    <React.Fragment>
      <NewTeamDialog />
      <Dialog
        scroll='paper'
        maxWidth='xl'
        open={true}
        fullScreen
      >
        <DialogContent>
          <Container component="main" maxWidth="xl">
            <Box p={3}>
              <Grid container justifyContent={"center"} spacing={2}>
                {
                  selectedTeam.slots.map((item) => {
                    return <>
                      <Grid item>
                        <KuroroSlot teamId={selectedTeam.id} slot={item} />
                      </Grid>
                    </>
                  })
                }
                {
                  selectedTeam.slots.length < 7 ? (
                    <Grid item>
                      <KuroroSlot teamId='' />
                    </Grid>
                  ) : null
                }
              </Grid>
              {
                selectedSlot.id ? (
                  <Grid item xs={12}>
                    <Grid container style={{
                      padding: upMd ? '20px' : '0'
                    }}>
                      <Grid item xs={12} md={6}>
                        <Dialog
                          fullScreen
                          open={true}
                          onClose={() => {
                            resetSlot()
                          }}
                          TransitionComponent={Transition}
                        >
                          <AppBar sx={{
                            position: 'fixed'
                          }}>
                            <Toolbar>
                              <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => {
                                  resetSlot()

                                }}
                                aria-label="close"
                              >
                                <CloseIcon />
                              </IconButton>
                              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                Select kuroro
                              </Typography>
                              <Button autoFocus color="inherit" onClick={() => {
                                saveSlot()
                              }}>
                                save slot
                              </Button>
                            </Toolbar>
                          </AppBar>
                          <Container component="main" maxWidth="md">

                            <Grid container style={{
                              marginTop: '50px'
                            }}>
                              {
                                selectedSlot.kuroro ? (
                                  <Grid item xs={12}>
                                    <SelectedKuroro />
                                  </Grid>
                                ) : <Grid item xs={12}>
                                  <KuroroPickupList />
                                </Grid>
                              }
                            </Grid>
                          </Container>
                        </Dialog>
                      </Grid>
                      <Grid item xs={12} md={6}>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : <Grid container spacing={3} style={{
                }}>
                  <Grid item xs={12} style={{
                    marginTop: '20px'
                  }}>
                    <Grid container justifyContent={"center"} spacing={3}>
                      <Grid item xs={12} md={6}>
                        <div style={{
                          display: 'flex',
                          justifyContent: 'center'
                        }}>
                          <FormControlLabel control={<Switch onChange={(e) => {
                            dispatch(setViewSelectedToPlayState({
                              viewSelectedToPlay: e.target.checked
                            }))
                          }} checked={viewSelectedToPlay} />} label="Calculate selected slots" />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div style={{
                          display: 'flex',
                          justifyContent: 'center'
                        }}>
                          <Button variant="contained" color="secondary" onClick={(e) => {
                            dispatch(setOpenTypesChartState({
                              openTypesChart: true
                            }))
                          }} endIcon={<TableChartIcon />}>
                            View Type Chart
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '20px'
                    }}>
                      <Typography variant="h4" align="center" color="primary" fontWeight={"bold"}>
                        Team Defense { }
                      </Typography>
                      <Tooltip title={<>

                        <Typography>
                          The score indicates whether your team is weak or resistant to each type. If the score is positive, your team probably has more Kuroros resistant to the type in question. See how it works in the table below:
                        </Typography>
                        <table className='table-tooltip'>
                          <thead>
                            <th>
                              Damage Modifier
                            </th>
                            <th>
                              Resistance 0.5
                            </th>
                            <th>
                              Resistance 0.25 or Immune
                            </th>
                            <th>
                              Weakness 2x
                            </th>
                            <th>
                              Weakness 4x
                            </th>
                          </thead>
                          <tbody>
                            <td>
                              Points
                            </td>
                            <td>
                              +1
                            </td>
                            <td>
                              +1.5
                            </td>
                            <td>
                              -1
                            </td>
                            <td>
                              -1.5
                            </td>
                          </tbody>
                        </table>



                      </>}>
                        <InfoIcon color="primary" style={{
                          marginLeft: '10px'
                        }} />
                      </Tooltip>
                    </div>
                  </Grid>
                  {
                    atkTypeInfoMap.map((item, index) => {
                      let value = 0
                      let multiplier = 1
                      let color = undefined;
                      (viewSelectedToPlay ? selectedSlotsToPlay : selectedTeam.slots).forEach((slot) => {
                        slot.kuroro?.types.forEach((type) => {
                          const interaction = item.interactions.find((interaction) => {
                            return interaction.type.toLowerCase() === type.toLowerCase()
                          })
                          if (interaction) {
                            multiplier = interaction.atkValue * multiplier
                          }
                        })
                        if (multiplier === 4) {
                          value = value - 1.5
                        } else if (multiplier === 2) {
                          value = value - 1
                        } else if (multiplier === 0.5) {
                          value = value + 1
                        } else if (multiplier === 0.25) {
                          value = value + 1.5
                        } else if (multiplier === 0) {
                          value = value + 1.5
                        }

                        if (value > 0) {
                          color = 'rgba(0,255,93,1)'
                        } else if (value === 0) {
                          color = undefined
                        } else {
                          color = 'red'
                        }
                        multiplier = 1
                      })
                      return <Grid item key={index} xs={6} md={2}>
                        <div style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'column'
                        }}>

                          <Chip
                            style={{
                              background: kuroroTypeColorMap.find((itemColor) => {
                                return itemColor.type.toLowerCase() === item.type.toLocaleLowerCase()
                              })?.color,
                              color: item.type.toLowerCase() === 'light' ? 'black' : 'white',
                              margin: '10px'
                            }}
                            avatar={<Avatar style={{
                              background: 'black'
                            }} alt="Natacha" src={"/" + item.type.toLocaleLowerCase() + "-white.webp"} />}
                            label={<Typography fontWeight={"bold"}>
                              {
                                item.type
                              }
                            </Typography>
                            }
                            variant="outlined"
                          />
                          <Typography fontWeight={"bold"} color={color}>
                            {value > 0 ? '+' : null} {value}
                          </Typography>

                        </div>
                      </Grid>
                    })
                  }
                  <Grid item xs={12}>
                    <div style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '30px'
                    }}>
                      <Typography variant="h4" align="center" color="primary" fontWeight={"bold"}>
                        Team Type Coverage
                      </Typography>
                      <Tooltip title={<>
                        <Typography>
                          The score indicates which types your team has an advantage through moves present in your Kuroros. So for each type that a move from your Kuroro has an advantage, one point is added. If the move has STAB, one more point is added.
                        </Typography>
                        <Typography fontWeight={"bold"} color="secondary">
                          +1 Point to each Type that your move is Super Effective
                        </Typography >
                        <Typography style={{
                          marginTop: '20px'
                        }} fontWeight={"bold"} color="secondary">
                          +1 Bonus Point if the Move has the same type of the Kuroro (Stab: Same type attack bonus)
                        </Typography >

                      </>}>
                        <InfoIcon color="primary" style={{
                          marginLeft: '10px'
                        }} />
                      </Tooltip>
                    </div>
                  </Grid>
                  {
                    atkTypeInfoMap.map((item, index) => {
                      let value = 0
                      let alreadyAdded: string[] = []
                      let color = undefined;
                      (viewSelectedToPlay ? selectedSlotsToPlay : selectedTeam.slots).forEach((slot) => {
                        slot.moves.forEach((move) => {
                          const interaction = item.interactions.find(i => i.type.toLowerCase() === move.type.toLowerCase())
                          if (interaction) {
                            console.log(alreadyAdded)
                            if (!alreadyAdded.includes(interaction.type.toLowerCase())) {
                              if (interaction.defValue === 2) {
                                value = value + 1
                                console.log("Kuorro types", slot.kuroro?.types)
                                console.log(move)
                                if (slot.kuroro?.types.includes(move.type.toUpperCase() as IKuroroType)) {
                                  value = value + 1
                                  console.log("asd", value)
                                }
                              }
                              alreadyAdded.push(move.type.toLowerCase())
                            }
                          }
                        })



                        if (value > 0) {
                          color = 'rgba(0,255,93,1)'
                        } else if (value === 0) {
                          color = undefined
                        } else {
                          color = 'red'
                        }
                      })
                      return <Grid item key={index} xs={6} md={2}>
                        <div style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'column'
                        }}>

                          <Chip
                            style={{
                              background: kuroroTypeColorMap.find((itemColor) => {
                                return itemColor.type.toLowerCase() === item.type.toLocaleLowerCase()
                              })?.color,
                              color: item.type.toLowerCase() === 'light' ? 'black' : 'white',
                              margin: '10px'
                            }}
                            avatar={<Avatar style={{
                              background: 'black'
                            }} alt="Natacha" src={"/" + item.type.toLocaleLowerCase() + "-white.webp"} />}
                            label={<Typography fontWeight={"bold"}>
                              {
                                item.type
                              }
                            </Typography>
                            }
                            variant="outlined"
                          />
                          <Typography color={color} fontWeight={"bold"}>
                            {value > 0 && '+'}{value}
                          </Typography>
                        </div>
                      </Grid>
                    })
                  }
                </Grid>
              }


            </Box>
          </Container>

        </DialogContent>
        <DialogActions style={{
          padding: '20px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <Button variant="contained" color="error" onClick={(e) => {
            dispatch(setSelectedSlotsToPlayState({
              slotsToPlay: []
            }))
            dispatch(setAction({
              action: 'list'
            }))
            navigate('/workspace/team-builder')
          }}>
            <Typography fontWeight={"bold"} variant="h5" >
              Cancel
            </Typography>
          </Button>
          <Button variant='contained' style={{
            marginLeft: '30px'
          }} color="primary" onClick={(e) => {
            if (selectedTeam.id === 'teamOfTheWeek') {
              dispatch(setSelectedSlotsToPlayState({
                slotsToPlay: []
              }))
              dispatch(setAction({
                action: 'list'
              }))
              navigate('/workspace/team-builder')
            } else if (selectedTeam.id === 'undefined') {
              setOpenNewTeamDialog({
                open: true
              })
            } else {
              updateTeam()
            }
          }}>
            <Typography variant="h5" fontWeight={"bold"}>
              Done
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="xl"
        onClose={() => {
          dispatch(setOpenTypesChartState({
            openTypesChart: false
          }))
        }} open={openTypesChart}>
        <DialogTitle>Types Interaction Table</DialogTitle>
        <Box p={3}>
          <TypesDoubleEntryTable />
        </Box>
        <DialogActions style={{
          justifyContent: 'center'
        }}>
          <Button onClick={(e) => {
            dispatch(setOpenTypesChartState({
              openTypesChart: false
            }))
          }} color="error" variant="contained">
            <Typography fontWeight={"bold"}>
              Close
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}