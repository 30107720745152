import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IFormError, IFormProps } from '../common'
import { validateEmail } from '../../utils'


interface ISignInFormFields {
  email: string
  password: string
}

interface IRequestNewPasswordFields {
  email: string
}

interface IResetPasswordFields {
  password: string
  confirmPassword: string
}

interface IAuthState {
  isValid: boolean
  token: string
  tokenReady?: boolean
  signInForm: IFormProps<ISignInFormFields>
  requestNewPasswordForm: IFormProps<IRequestNewPasswordFields>
  resetPasswordForm: IFormProps<IResetPasswordFields>
}

const initialState: IAuthState = {
  isValid: false,
  tokenReady: false,
  token: '',
  signInForm: {
    errors: [],
    fields: {
      email: '',
      password: ''
    },
    touched: false
  },
  requestNewPasswordForm: {
    errors: [],
    fields: {
      email: ''
    },
    touched: false
  },
  resetPasswordForm: {
    errors: [],
    fields: {
      confirmPassword: '',
      password: ''
    },
    touched: false,
    delivered: false
  }
};


export const validateSignInForm = (fields: ISignInFormFields): IFormError<keyof ISignInFormFields>[] => {
  const errors: IFormError<keyof ISignInFormFields>[] = [];
  if (fields.email === '' || !fields.email) {
    errors.push({
      key: 'email',
      message: 'E-mail is a required field'
    })
  }

  if (fields.password === '' || !fields.password) {
    errors.push({
      key: 'password',
      message: 'E-mail is a required field'
    })
  }
  return errors
}

export const validateRequestNewPasswordForm = (fields: IRequestNewPasswordFields): IFormError<keyof IRequestNewPasswordFields>[] => {
  const errors: IFormError<keyof IRequestNewPasswordFields>[] = [];
  if (fields.email === '' || !fields.email) {
    errors.push({
      key: 'email',
      message: 'E-mail is a required field'
    })
  } else if (!validateEmail(fields.email)) {
    errors.push({
      key: 'email',
      message: 'Invalid e-mail format'
    });
  }
  return errors
}

export const validateResetPasswordForm = (fields: IResetPasswordFields): IFormError<keyof IResetPasswordFields>[] => {
  const errors: IFormError<keyof IResetPasswordFields>[] = [];




  /* Validate Password and ConfirmPassword */
  if (fields.password === '' || fields.password === undefined) {
    errors.push({
      key: 'password',
      message: 'Password is a required field'
    })
  } else {
    const regex = /^(?=.*[!@#$%^&*()-_+=|{}[\]:;<>,.?/~`])(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (!regex.test(fields.password)) {
      errors.push({
        key: 'password',
        message: 'Password must be at least 8 characters long and include at least one lowercase letter, one uppercase letter, one number, and one special character'
      })
    }
    if (fields.confirmPassword !== fields.password) {
      errors.push({
        key: 'confirmPassword',
        message: 'Passwords do not match'
      })
    }
  }

  return errors
}




const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setTokenState(state, action: PayloadAction<{
      token: string
    }>) {
      state.token = action.payload.token
    },
    setAuthIsValidState(state, action: PayloadAction<{
      isValid: boolean
    }>) {
      state.isValid = action.payload.isValid
    },
    setAuthTokenReadyState(state, action: PayloadAction<{
      ready: boolean
    }>) {
      state.tokenReady = action.payload.ready
    },
    setSignInFormEmail(state, action: PayloadAction<{
      email: string
    }>) {
      state.signInForm.fields.email = action.payload.email
    },
    setSignInFormPassword(state, action: PayloadAction<{
      password: string
    }>) {
      state.signInForm.fields.password = action.payload.password
    },
    setSignInFormTouched(state, action: PayloadAction<{
      touched: boolean
    }>) {
      state.signInForm.touched = action.payload.touched
    },
    setSignInFormErrors(state, action: PayloadAction<{
      errors: IFormError<keyof ISignInFormFields>[]
    }>) {
      state.signInForm.errors = action.payload.errors
    },
    setRequestNewPasswordFormEmail(state, action: PayloadAction<{
      email: string
    }>) {
      state.requestNewPasswordForm.fields.email = action.payload.email
    },
    setRequestNewPasswordFormTouched(state, action: PayloadAction<{
      touched: boolean
    }>) {
      state.requestNewPasswordForm.touched = action.payload.touched
    },
    setRequestNewPasswordFormErrors(state, action: PayloadAction<{
      errors: IFormError<keyof IRequestNewPasswordFields>[]
    }>) {
      state.requestNewPasswordForm.errors = action.payload.errors
    },
    setResetPasswordFormPassword(state, action: PayloadAction<{
      password: string
    }>) {
      state.resetPasswordForm.fields.password = action.payload.password
    },
    setResetPasswordFormConfirmPassword(state, action: PayloadAction<{
      confirmPassword: string
    }>) {
      state.resetPasswordForm.fields.confirmPassword = action.payload.confirmPassword
    },
    setResetPasswordFormTouched(state, action: PayloadAction<{
      touched: boolean
    }>) {
      state.resetPasswordForm.touched = action.payload.touched
    },
    setResetPasswordFormErrors(state, action: PayloadAction<{
      errors: IFormError<keyof IResetPasswordFields>[]
    }>) {
      state.resetPasswordForm.errors = action.payload.errors
    },
    resetAuthState(state) {
      state = initialState
    }
  },
});

export const {
  setTokenState,
  resetAuthState,
  setSignInFormEmail,
  setSignInFormErrors,
  setSignInFormPassword,
  setSignInFormTouched,
  setAuthIsValidState,
  setAuthTokenReadyState,
  setRequestNewPasswordFormEmail,
  setRequestNewPasswordFormErrors,
  setRequestNewPasswordFormTouched,
  setResetPasswordFormConfirmPassword,
  setResetPasswordFormErrors,
  setResetPasswordFormPassword,
  setResetPasswordFormTouched
} = authSlice.actions;
export const authReducer = authSlice.reducer;

