import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Copyright } from '../sign-up';
import { CircularProgress, Grid, Link } from '@mui/material';
import { useAppSelector } from '../../../hooks';
import { useAuthService } from '../../../services/auth';

export function RequestNewPasswordPage() {
  const requestNewPasswordForm = useAppSelector((state) => state.auth.requestNewPasswordForm)
  const { requestNewPassword, setRequestNewPasswordEmail } = useAuthService()
  const [loading, setLoading] = React.useState(false)
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true)
    requestNewPassword().then(() => {
      setLoading(false)
    })
  };


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Request New Password
        </Typography>
        <Typography style={{
          marginTop: '10px'
        }} variant="caption">
          Enter your email, and we will send you a link to reset your password.
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>

          <TextField
            value={requestNewPasswordForm.fields.email}
            onChange={(e) => {
              setRequestNewPasswordEmail({
                email: e.target.value
              })
            }}
            error={Boolean(requestNewPasswordForm.touched && requestNewPasswordForm.errors.find(item => item.key === 'email'))}
            helperText={
              requestNewPasswordForm.errors.find(item => item.key === 'email')?.message
            }
            autoComplete="given-name"
            name="email"
            fullWidth
            id="email"
            label="E-mail"
            placeholder='Type your e-mail'
            margin="normal"
            required
            type="email"
            autoFocus
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={loading}
          >
            {
              loading ? (
                <CircularProgress />
              ) : "Request New Password"
            }

          </Button>
          <Grid container justifyContent={"end"}>
            <Grid item>
              <Link href="/auth/sign-in" variant="body2">
                Return to Sign In Page
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}