
import Typography from '@mui/material/Typography';
import { useAuthService } from '../../../services/auth';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import ErrorIcon from '@mui/icons-material/Error';
import { Box, Button, CircularProgress, Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useUiService } from '../../../services/ui';
export function EmailConfirmationPage() {
  const { confirmEmail, resendConfirmEmail } = useAuthService()
  const [searchParams] = useSearchParams();
  const [showMessage, setShowMessage] = useState(false)
  const [isError, setIsError] = useState(false)
  const [loadingResend, setLoadingResend] = useState(false)
  const { setAlert } = useUiService()
  useEffect(() => {
    if (searchParams.get('token')) {
      confirmEmail({
        token: searchParams.get('token') ?? ''
      }).then(() => {
      }).catch((err) => {
        setIsError(true)
      }).finally(() => {
        setShowMessage(true)
      })
    }
  }, [
    confirmEmail,
    searchParams
  ])
  const navigate = useNavigate()
  return (
    <>
      <Container component={"main"} maxWidth="xs">
        {
          showMessage ? (
            <>
              {
                isError ? (
                  <>
                    <Typography style={{
                      marginTop: '30px'
                    }} variant="h4" align="center" color="secondary">
                      Account Token Expired
                    </Typography>
                    <Typography variant="subtitle1" color="secondary" align="center" style={{
                      margin: '20px 0'
                    }}>
                      It seems the token associated with your account has expired and is no longer valid. Please click the button below to resend the confirmation email and receive a new token to complete your registration.
                    </Typography>
                    <Box display={"flex"} justifyContent={"center"}>
                      <ErrorIcon color="secondary" fontSize='large' />
                    </Box>
                    <Box display={"flex"} justifyContent={"center"} mt={2}>
                      <Button disabled={loadingResend} onClick={() => {
                        setLoadingResend(true)

                        resendConfirmEmail({
                          token: searchParams.get("token") ?? ""
                        }).then(() => {
                          setLoadingResend(false)
                          setAlert({
                            message: 'Your confirmation email has been resent.',
                            type: 'success'
                          })
                        }).catch((err) => {
                          setLoadingResend(false)
                          setAlert({
                            message: err.message,
                            type: 'error'
                          })
                        })
                      }} color="secondary" variant="outlined">
                        {
                          loadingResend ? (
                            <CircularProgress />
                          ) : "Resend E-mail"
                        }

                      </Button>
                    </Box>
                    <Box display={"flex"} justifyContent={"center"} mt={2}>
                      <img src="/stratzord-logo-horizontal.png" alt="Logo stratzord" style={{
                        width: '200px',
                        maxWidth: '100%'
                      }} />
                    </Box>
                  </>
                ) : <>
                  <Typography style={{
                    marginTop: '30px'
                  }} variant="h4" align="center" color="secondary">
                    Email Confirmation Successful
                  </Typography>
                  <Typography variant="subtitle1" color="secondary" align="center">
                    Your email has been confirmed
                    successfully. Thank you!
                  </Typography>
                  <Box display={"flex"} justifyContent={"center"}>
                    <MarkEmailReadIcon color="secondary" fontSize='large' />
                  </Box>
                  <Box display={"flex"} justifyContent={"center"} mt={2}>
                    <Button onClick={() => {
                      navigate("/auth/sign-in")
                    }} color="secondary" variant="outlined">
                      Sign In
                    </Button>
                  </Box>
                  <Box display={"flex"} justifyContent={"center"} mt={2}>
                    <img src="/stratzord-logo-horizontal.png" alt="Logo stratzord" style={{
                      width: '200px',
                      maxWidth: '100%'
                    }} />
                  </Box>
                </>
              }

            </>
          ) : null
        }

      </Container>
    </>
  );
}