import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Autocomplete, Avatar, Box, Chip, Grid, TextField, Typography } from '@mui/material';
import { atkTypeInfoMap, kuroroTypeColorMap } from './SelectedKuroro';
import { useAppDispatch, useAppSelector } from '../hooks';
import { setSelectedKuroroTeamSlotState, setSelectedSlotsToPlayState } from '../application/team-builder';
export function MoveListPicker(props: {
  open: boolean,
  setOpen: any
}) {
  const handleClose = () => {
    props.setOpen(false);
  };

  const dispatch = useAppDispatch()
  const selectedSlot = useAppSelector((state) => state.teamBuilder.selectedSlot)
  const [value, setValue] = React.useState<string | null>(null);
  const [autoCompleteInputValue, setAutoCompleteInputValue] = React.useState('');
  const [inputValue, setInputValue] = React.useState('')
  const moves = useAppSelector((state) => state.kuroroWIki.moves)
  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Choose the move"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Filter by name and other properties like category, power etc.
          </DialogContentText>
          <Box m={3}>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Move name"
                  placeholder='Ex: Acidic Flux'
                  value={inputValue}
                  onChange={(e) => {
                    setInputValue(e.target.value)
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  value={value}
                  onChange={(event: any, newValue: string | null) => {
                    setValue(newValue);
                  }}
                  inputValue={autoCompleteInputValue}
                  onInputChange={(event, newInputValue) => {
                    setAutoCompleteInputValue(newInputValue);
                  }}
                  id="controllable-states-demo"
                  options={atkTypeInfoMap.map((item) => {
                    return item.type
                  })}
                  renderInput={(params) => <TextField  {...params} label="Type" />}
                />
              </Grid>
              {
                moves.filter((item) => {
                  return item.move.toLowerCase().includes(inputValue.toLowerCase())
                }).filter((item) => {
                  if (!value) return true
                  return item.type.toLowerCase() === value.toLowerCase()
                }).map((item, index) => {
                  return <>
                    <Grid item xs={12} md={4} key={index}>
                      <Button style={{
                        background: `${kuroroTypeColorMap.find((itemColorMap) => {
                          return itemColorMap.type.toLowerCase() === item.type.toLowerCase()
                        })?.color}`,
                        color: `${kuroroTypeColorMap.find((itemColorMap) => {
                          return itemColorMap.type.toLowerCase() === item.type.toLowerCase()
                        })?.textColor ?? 'white'}`,
                        borderRadius: '25px',
                        border: '1px white solid'
                      }} fullWidth onClick={(e) => {
                        dispatch(setSelectedSlotsToPlayState({
                          slotsToPlay: []
                        }))
                        dispatch(setSelectedKuroroTeamSlotState({
                          kuroroTeamSlot: {
                            ...selectedSlot,
                            moves: [...selectedSlot.moves.filter((itemMovesSelected) => {
                              return itemMovesSelected.move !== item.move
                            }), {
                              id: item.id,
                              category: item.category as any,
                              description: item.description,
                              move: item.move,
                              power: item.power,
                              type: item.type as any
                            }]
                          }
                        }))
                        handleClose()
                      }}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid container flexWrap={"nowrap"} spacing={3}>
                              <Grid item style={{

                              }}>
                                <div style={{
                                  height: '100%',
                                }}>
                                  <div style={{
                                  }}>
                                    <div style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      padding: '10px'
                                    }}>

                                      <Typography style={{
                                        textShadow: `2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff,
                                      1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff;`
                                      }} align='left' fontWeight={"bold"} variant="h6">

                                        {item.move}
                                      </Typography>
                                    </div>
                                    <Grid container spacing={3} style={{
                                      padding: '10px'
                                    }}>
                                      <Grid item xs={6}>
                                        <Typography fontWeight={"bold"} color="secondary" align='left' style={{
                                          color: `${kuroroTypeColorMap.find((itemColorMap) => {
                                            return itemColorMap.type.toLowerCase() === item.type.toLowerCase()
                                          })?.textColor ?? 'white'}`,
                                        }}>
                                          Power: {item.power}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <Typography fontWeight={"bold"} color="secondary" style={{
                                          color: `${kuroroTypeColorMap.find((itemColorMap) => {
                                            return itemColorMap.type.toLowerCase() === item.type.toLowerCase()
                                          })?.textColor ?? 'white'}`,
                                        }}>
                                          Category: {item.category}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    <Grid container justifyContent={"start"} style={{
                                    }}>
                                      <Grid item>
                                        <Chip
                                          style={{
                                            background: kuroroTypeColorMap.find((itemColor) => {
                                              return itemColor.type.toLowerCase() === item.type.toLowerCase()
                                            })?.color,
                                            color: `${kuroroTypeColorMap.find((itemColorMap) => {
                                              return itemColorMap.type.toLowerCase() === item.type.toLowerCase()
                                            })?.textColor ?? 'white'}`, margin: '10px'
                                          }}
                                          avatar={<Avatar style={{
                                            background: 'black'
                                          }} alt="Natacha" src={"/" + item.type.toLowerCase() + "-white.webp"} />}
                                          label={<Typography style={{
                                            color: `${kuroroTypeColorMap.find((itemColorMap) => {
                                              return itemColorMap.type.toLowerCase() === item.type.toLowerCase()
                                            })?.textColor ?? 'white'}`,
                                          }} fontWeight={"bold"}>
                                            {
                                              item.type
                                            }
                                          </Typography>
                                          }
                                          variant="outlined"
                                        />
                                      </Grid>
                                    </Grid>

                                  </div>
                                  <div style={{
                                    height: '75px',
                                    overflowY: 'auto',
                                    padding: '10px'
                                  }}>
                                    <Typography align="left" style={{
                                      color: `${kuroroTypeColorMap.find((itemColorMap) => {
                                        return itemColorMap.type.toLowerCase() === item.type.toLowerCase()
                                      })?.textColor ?? 'white'}`,
                                    }}>
                                      {item.description}
                                    </Typography>
                                  </div>

                                </div>

                              </Grid>
                            </Grid>

                          </Grid>

                        </Grid>

                      </Button>

                    </Grid>
                  </>

                })
              }
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}