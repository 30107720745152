import { useCallback, useState } from "react"
import { useTeamApi } from "../../infra/team"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { initialTeam, initialTeamSlot, saveTeamSlot, setAction, setMyTeamsState, setOpenCreateAccountState, setOpenNewTeamDialogState, setOpenUpdateTeamSettingsState, setSelectedKuroroTeamSlotState, setSelectedKuroroTeamState, setUpdateTeamSettingsFormMadeItPublic } from "../../application/team-builder"
import { useUiService } from "../ui"
import { useNavigate } from "react-router-dom"
import { teamOfTheWeekInstance } from "../../pages/workspace/team-builder/TeamOfTheWeek"

export const useTeamBuilderService = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { getTeamsByUserNicknameApi, deleteTeamApi, createTeamApi, updateTeamApi, getTeamByIdApi } = useTeamApi()
  const [openEdit, setOpenEdit] = useState(false);

  const myTeams = useAppSelector((state) => state.teamBuilder.myTeams)
  const currentUser = useAppSelector((state) => state.user.currentUser)
  const selectedTeam = useAppSelector((state) => state.teamBuilder.selectedTeam)

  const { setAlert } = useUiService()

  const setupCreateTeam = useCallback(() => {
    navigate('/workspace/team-builder/new-team')
  }, [
    navigate
  ])

  const setMyTeams = useCallback(() => {
    if (currentUser.id) {
      getTeamsByUserNicknameApi({
        nickname: currentUser.nickname
      }).then((response) => {
        dispatch(setMyTeamsState({
          myTeams: response
        }))
      })
    } else {

    }
  }, [
    currentUser.nickname,
    currentUser.id,
    dispatch,
    getTeamsByUserNicknameApi
  ])

  const deleteTeam = useCallback((data: {
    teamId: string
  }) => {
    deleteTeamApi({
      teamId: data.teamId
    }).then(() => {
      setAlert({
        message: 'Team deleted successfully',
        type: 'success'
      })
      dispatch(setMyTeamsState({
        myTeams: myTeams.filter((item) => {
          return data.teamId !== item.id
        })
      }))
    }).catch((err) => {
      setAlert({
        message: err.message,
        type: 'error'
      })
    })
  }, [
    setAlert,
    deleteTeamApi,
    dispatch,
    myTeams
  ])

  const createTeam = useCallback((data: {
    userId?: string,
    nickname?: string
  }) => {
    const findNull = selectedTeam.slots.find((item) => {
      return !item.kuroro
    })
    if (findNull) {
      setAlert({
        message: 'All slots must be filled',
        type: 'error'
      })
      return
    }
    if (currentUser.id || data.userId) {
      createTeamApi({
        nickname: data.nickname ? data.nickname : currentUser.nickname,
        team: selectedTeam
      }).then(() => {
        setAlert({
          message: 'Team created successfully',
          type: 'success'
        })
        setMyTeams()
        dispatch(setSelectedKuroroTeamState({
          kuroroTeam: initialTeam
        }))
        navigate('/workspace/team-builder')
        dispatch(setAction({
          action: 'list'
        }))
      }).catch((err) => {
        setAlert({
          message: err.message,
          type: 'error'
        })
      })
    } else {
      dispatch(setOpenCreateAccountState({
        openCreateAccount: true
      }))
    }
  }, [
    createTeamApi,
    currentUser.nickname,
    selectedTeam,
    setAlert,
    setMyTeams,
    currentUser.id,
    dispatch,
    navigate
  ])

  const setOpenUpdateTeamSettings = useCallback((data: {
    open: boolean
  }) => {
    dispatch(setOpenUpdateTeamSettingsState({
      open: data.open
    }))
    if (!data.open) {
      dispatch(setAction({
        action: 'list'
      }))
    }
  }, [
    dispatch
  ])
  const updateTeam = useCallback(() => {
    if (!selectedTeam.name)
      return
    const findNull = selectedTeam.slots.find((item) => {
      return !item.kuroro
    })
    if (findNull) {

      setAlert({
        message: 'All slots must be filled',
        type: 'error'
      })
      return
    }
    updateTeamApi({
      nickname: currentUser.nickname,
      team: selectedTeam
    }).then(() => {
      setAlert({
        message: 'Team updated successfully',
        type: 'success'
      })
      dispatch(setAction({
        action: 'list'
      }))
      setOpenEdit(false)
      setOpenUpdateTeamSettings({
        open: false
      })
      setMyTeams()
      navigate('/workspace/team-builder')
    }).catch((err) => {
      setAlert({
        message: err.message,
        type: 'error'
      })
    })
  }, [
    updateTeamApi,
    currentUser.nickname,
    selectedTeam,
    setAlert,
    setMyTeams,
    dispatch,
    navigate,
    setOpenUpdateTeamSettings
  ])


  const getTeamById = useCallback((data: {
    teamId: string
  }) => {
    if (data.teamId === 'teamOfTheWeek') {
      dispatch(setAction({
        action: 'edit'
      }))
      dispatch(setSelectedKuroroTeamSlotState({
        kuroroTeamSlot: initialTeamSlot
      }))
      dispatch(setSelectedKuroroTeamState({
        kuroroTeam: teamOfTheWeekInstance as any
      }))
    } else if (data.teamId === 'new-team') {

      dispatch(setAction({
        action: 'edit'
      }))
      dispatch(setSelectedKuroroTeamSlotState({
        kuroroTeamSlot: initialTeamSlot
      }))
      const slots = []
      for (let i = 0; i < 7; i++) {
        slots.push({
          ...initialTeamSlot,
          id: Math.random()
        })
      }
      dispatch(setSelectedKuroroTeamState({
        kuroroTeam: {
          ...initialTeam,
          slots: slots

        }
      }))
    } else {
      getTeamByIdApi({
        teamId: data.teamId
      }).then((response) => {
        dispatch(setAction({
          action: 'edit'
        }))
        dispatch(setSelectedKuroroTeamSlotState({
          kuroroTeamSlot: initialTeamSlot
        }))
        dispatch(setSelectedKuroroTeamState({
          kuroroTeam: response
        }))
      }).catch((err) => {
        navigate('/workspace/team-builder/team-not-found')
      })
    }
  }, [
    getTeamByIdApi,
    dispatch,
    navigate
  ])
  const setupTeamEdit = useCallback((data: {
    teamId: string
  }) => {
    getTeamById(data)
  }, [
    getTeamById
  ])


  const saveSlot = useCallback(() => {
    dispatch(saveTeamSlot())
  }, [dispatch])

  const resetSlot = useCallback(() => {
    dispatch(setSelectedKuroroTeamSlotState({
      kuroroTeamSlot: initialTeamSlot
    }))
  }, [dispatch])

  const removeSelectedSlot = useCallback((data: {
    slotId: number
  }) => {
    dispatch(setSelectedKuroroTeamState({
      kuroroTeam: {
        ...selectedTeam,
        slots: selectedTeam.slots.map((item) => {
          if (item.id !== data.slotId) {
            return item
          } else {
            return {
              ...item,
              kuroro: undefined
            }
          }
        })
      }
    }))
  }, [dispatch, selectedTeam])


  const setOpenAlertCreateAccount = useCallback((data: {
    open: boolean
  }) => {
    dispatch(setOpenCreateAccountState({
      openCreateAccount: data.open
    }))
  }, [
    dispatch
  ])


  const setOpenNewTeamDialog = useCallback((data: {
    open: boolean
  }) => {
    dispatch(setOpenNewTeamDialogState({
      openNewTeamDialog: data.open
    }))
  }, [
    dispatch
  ])

  const setUpdateTeamSettingsName = useCallback((data: {
    name: string
  }) => {
    dispatch(setSelectedKuroroTeamState({
      kuroroTeam: {
        ...selectedTeam,
        name: data.name
      }
    }))
  }, [
    dispatch,
    selectedTeam
  ])

  const setupUpdateTeamSettings = useCallback((data: {
    teamId: string
  }) => {
    setOpenUpdateTeamSettings({
      open: true
    })
    getTeamByIdApi({
      teamId: data.teamId
    }).then((response) => {
      dispatch(setSelectedKuroroTeamState({
        kuroroTeam: response
      }))
      dispatch(setSelectedKuroroTeamSlotState({
        kuroroTeamSlot: initialTeamSlot
      }))
      dispatch(setSelectedKuroroTeamState({
        kuroroTeam: response
      }))
    })
  }, [
    setOpenUpdateTeamSettings,
    dispatch,
    getTeamByIdApi
  ])

  const setUpdateTeamSettingsMadeItPublic = useCallback((data: {
    madeItPublic: boolean
  }) => {
    dispatch(setUpdateTeamSettingsFormMadeItPublic({
      madeItPublic: data.madeItPublic
    }))
  }, [
    dispatch,
  ])

  return {
    setMyTeams,
    deleteTeam,
    createTeam,
    updateTeam,
    setOpenAlertCreateAccount,
    getTeamById,
    openEdit,
    setOpenEdit,
    saveSlot,
    resetSlot,
    removeSelectedSlot,
    setOpenNewTeamDialog,
    setupCreateTeam,
    setUpdateTeamSettingsName,
    setUpdateTeamSettingsMadeItPublic,
    setupTeamEdit,
    setOpenUpdateTeamSettings,
    setupUpdateTeamSettings
  }
}