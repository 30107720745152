import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@mui/icons-material/Add';
import { Box, Slide } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { setSelectedKuroroTeamState } from '../../../application/team-builder';
import ReactGA from "react-ga4";
import { useTeamBuilderService } from '../../../services/team-builder';
import { TransitionProps } from '@mui/material/transitions';
import { useNavigate } from 'react-router-dom';


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export function NewTeamDialog() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const selectedTeam = useAppSelector((state) => state.teamBuilder.selectedTeam)
  const openAlertCreateAccount = useAppSelector((state) => state.teamBuilder.openCreateAccount)
  const openNewTeamDialog = useAppSelector((state) => state.teamBuilder.openNewTeamDialog)
  const { createTeam, setOpenAlertCreateAccount, setOpenNewTeamDialog } = useTeamBuilderService()



  const handleClose = () => {
    setOpenNewTeamDialog({
      open: false
    })
  };
  const { setupCreateTeam } = useTeamBuilderService()

  return (
    <React.Fragment>
      <Dialog
        style={{
          zIndex: 9000
        }}
        open={openAlertCreateAccount}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setOpenAlertCreateAccount({
            open: false
          })
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Team Creation Requires Account Registration  "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            To create a team, you must register an account. or <Button color="secondary" onClick={() => {
              navigate('/auth/sign-in')
            }}>
              Sign in
            </Button>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={() => {
            setOpenAlertCreateAccount({
              open: false
            })
          }}>Cancel</Button>
          <Button color="secondary" onClick={() => {
            setOpenAlertCreateAccount({
              open: false
            })
            navigate('/auth/sign-up')
          }}>Sign up</Button>
        </DialogActions>
      </Dialog>
      <Button onClick={() => {
        setupCreateTeam()
      }} endIcon={<AddIcon />} color="primary" variant="contained">
        New Team
      </Button>
      <Dialog
        style={{
          zIndex: 5000
        }}
        open={openNewTeamDialog}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            ReactGA.event({
              category: "create_team",
              action: "create_team",
            });
            createTeam({})
            handleClose();
          },
        }}
      >
        <DialogTitle>New Team</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please choose a name to get started! You'll be able to add Kuroros to each team slot. Within each Kuroro, you can view their stats, type interactions, and select their moves.          </DialogContentText>
          <Box mt={3}>

            <TextField
              autoFocus
              required
              id="name"
              name="email"
              label="Nome do time"
              fullWidth
              value={selectedTeam.name}
              onChange={(e) => {
                dispatch(setSelectedKuroroTeamState({
                  kuroroTeam: {
                    ...selectedTeam,
                    name: e.target.value
                  }
                }))
              }}
              variant="outlined"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button style={{
            color: 'white'
          }} onClick={handleClose}>Cancel</Button>
          <Button style={{
            color: 'white'
          }} type="submit">Create team</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
