import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type IAlertType = 'error' | 'warning' | 'success'

interface IUISliceState {

}

const initialState: IUISliceState = {

};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setUIAlertState(state, action: PayloadAction<{
      type: IAlertType,
      message: string
    }>) {

    },

  },
});

export const {
  setUIAlertState
} = uiSlice.actions;
export const uiReducer = uiSlice.reducer;