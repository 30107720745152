import { Grid, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material"
import { IKuroroTeam } from "../application/team-builder"
import DeleteIcon from '@mui/icons-material/Delete';
import { KuroroSlot } from "./KuroroSlot";
import { useTeamBuilderService } from "../services/team-builder/useTeamBuilderService";
import { useNavigate } from "react-router-dom";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SettingsIcon from '@mui/icons-material/Settings';
export const KuroroTeamSlot = (props: {
  team: IKuroroTeam,
  readOnly?: boolean
}) => {
  const { deleteTeam, setupUpdateTeamSettings } = useTeamBuilderService()
  const theme = useTheme()
  const isSm = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate()
  return <>

    <Grid style={{
      border: `5px solid ${theme.palette.primary.main}`,
      background: theme.palette.background.paper,
      borderRadius: '15px',
      height: '100%',
      padding: '15px',
      position: 'relative'
    }} container justifyContent={"space-between"} alignItems={"center"} >
      <div style={{
        position: 'absolute',
        top: 0,
        right: 0
      }}>
        <Grid container>
          <Grid item>
            <IconButton onClick={() => {
              navigate('/workspace/team-builder/' + props.team.id)
            }} >

              <OpenInNewIcon style={{
                color: theme.palette.common.white
              }} />


            </IconButton>
          </Grid>
          {
            !props.readOnly ? (
              <Grid item>
                <IconButton onClick={() => {
                  deleteTeam({
                    teamId: props.team.id
                  })
                }} color="error">
                  <DeleteIcon />

                </IconButton>
              </Grid>
            ) : null
          }
          {
            !props.readOnly ? (
              <Grid item>
                <IconButton onClick={() => {
                  setupUpdateTeamSettings({
                    teamId: props.team.id
                  })
                }} color="primary">
                  <SettingsIcon />
                </IconButton>
              </Grid>
            ) : null
          }
        </Grid>
      </div>

      <Grid item xs={12} style={{
      }}>
        <Typography style={{
          marginBottom: '20px'
        }} variant="h6" color={'secondary'} fontWeight={"bold"} >
          {props.team.name}
        </Typography>
      </Grid>
      <Grid item xs={12} style={{
      }}>
        <Grid container spacing={2} style={{
        }}>
          {
            props.team.slots.map((item) => {
              return <Grid item xs={isSm ? 6 : undefined} style={{
                display: 'flex',
                justifyContent: 'center',
              }}>
                <KuroroSlot teamId={props.team.id} slot={item} />
              </Grid>
            })
          }

        </Grid>
      </Grid>

    </Grid>
  </>
}