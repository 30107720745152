import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ConstructionIcon from '@mui/icons-material/Construction';
import LanguageIcon from '@mui/icons-material/Language';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import GamepadIcon from '@mui/icons-material/Gamepad';
import { useNavigate } from 'react-router-dom';
type Anchor = 'top' | 'left' | 'bottom' | 'right';


const menuItensTop: {
  value: string,
  icon: JSX.Element
  path: string
}[] = [
    {
      icon: <ConstructionIcon color='secondary' />,
      value: 'TeamBuilder',
      path: '/workspace/team-builder'
    },
    {
      icon: <MenuBookIcon color='secondary' />,
      value: 'Kuroro Wiki',
      path: '/workspace/kuroro-wiki'
    }
  ]

const menuItemsBottom: {
  value: string,
  icon: JSX.Element
  path?: string,
  onClick?: any
}[] = [
    {
      icon: <GamepadIcon color='secondary' />,
      value: 'Play Kuroro Beasts',
      path: '/workspace/play-kuroro-beasts'
    },
    {
      icon: <LanguageIcon color='secondary' />,
      value: 'About us',
      onClick: () => {
        window.open("https://www.stratzord.com/")
      }
    }
  ]

export default function MobileDrawer() {
  const [open, setOpen] = React.useState(false)
  const navigate = useNavigate()
  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event &&
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }

        setOpen(open)

      };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {menuItensTop.map((item, index) => (
          <ListItem key={item.value} disablePadding onClick={() => {
            navigate(item.path)
          }}>
            <ListItemButton>
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.value} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {menuItemsBottom.map((item, index) => (
          <ListItem key={item.value} disablePadding onClick={() => {
            if(item.onClick) {
              item.onClick()
            }else {
              navigate(item.path ?? '')
            }
          }}>
            <ListItemButton>
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.value} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment key={'left'}>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={toggleDrawer('left', true)}
        >
          <MenuIcon />
        </IconButton>
        <SwipeableDrawer
          anchor={'left'}
          open={open}
          onClose={toggleDrawer('left', false)}
          onOpen={toggleDrawer('left', true)}
        >
          {list('left')}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
