import { Grid, Typography } from "@mui/material"

export const CommunityHomePage = () => {
  return <>
      <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5" color="white" fontWeight={"bold"}>
          Community
        </Typography>
      </Grid>
   
    </Grid>
  </>
}