import { Navigate } from "react-router-dom"
import { useAppSelector } from "../../hooks"


interface IProps {
  children: JSX.Element
}

export const PrivateRoute = (props: IProps) => {
  const isValid = useAppSelector((state) => state.auth.isValid)
  const tokenReady = useAppSelector((state) => state.auth.tokenReady)

  return (
    <>
      {
        tokenReady ? (
          <>
            {
              isValid ? (
                <>
                  {props.children}
                </>
              ) : <Navigate to="/auth/sign-in" />
            }
          </>
        ) : null
      }

    </>
  )
}