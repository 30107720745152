import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IKuroro } from '../../types';
import { IKuroroMove } from '../../types/IKuroroMove';

interface IKuroroWikiState {
  kuroros: IKuroro[]
  moves: IKuroroMove[]
}

const initialState: IKuroroWikiState = {
  kuroros: [],
  moves: []
};

const kuroroWikiSlice = createSlice({
  name: 'kuroro-wiki',
  initialState,
  reducers: {
    setKurorosState(state, action: PayloadAction<{
      kuroros: IKuroro[]
    }>) {
      state.kuroros = action.payload.kuroros
    },
    setMovesState(state, action: PayloadAction<{
      moves: IKuroroMove[]
    }>) {
      state.moves = action.payload.moves
    },
  },
});

export const {
  setKurorosState,
  setMovesState
} = kuroroWikiSlice.actions;
export const kuroroWikiReducer = kuroroWikiSlice.reducer;

