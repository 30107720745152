import { useState } from "react"
import { IKuroroTeamSlot, addSlot, setSelectedKuroroTeamSlotState, setSelectedSlotsToPlayState } from "../application/team-builder"
import { Typography, useMediaQuery, useTheme } from "@mui/material"
import { useAppDispatch, useAppSelector } from "../hooks"
import { useTeamBuilderService } from "../services/team-builder"
import { useNavigate } from "react-router-dom"
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
export const KuroroSlot = (
  props: {
    slot?: IKuroroTeamSlot,
    teamId: string
  }
) => {
  const theme = useTheme()
  const upMd = useMediaQuery(theme.breakpoints.up('md'));
  const { removeSelectedSlot } = useTeamBuilderService()
  const [hover, setHover] = useState(false)
  const dispatch = useAppDispatch()
  const selectedSlot = useAppSelector((state) => state.teamBuilder.selectedSlot)
  const action = useAppSelector((state) => state.teamBuilder.action)
  const selectedSlotsToPlay = useAppSelector((state) => state.teamBuilder.selectedSlotsToPlay)
  const navigate = useNavigate()
  return <div onClick={(e) => {
    e.stopPropagation()
    if (action === 'edit') {
      if (props.slot) {
        dispatch(setSelectedKuroroTeamSlotState({
          kuroroTeamSlot: props.slot
        }))
      } else {
        dispatch(addSlot())
      }
    } else if (action === 'list') {
      navigate(`/workspace/team-builder/${props.teamId}`)
    }

  }}
    onMouseEnter={(e) => {
      setHover(true)
    }}
    onMouseLeave={(e) => {
      setHover(false)
    }}
    style={{
      width: upMd ? '150px' : '100px',
      height: upMd ? '150px' : '100px',
      maxWidth: action === 'edit' ? undefined : '80px',
      maxHeight: action === 'edit' ? undefined : '80px',

      transition: '0.3s all',
      backgroundColor: "rgb(192 189 193)",
      backgroundImage: props.slot ? `url(/kuroro-img/${props.slot.kuroro?.name.replaceAll(' ', '%20')}.gif)` : undefined,
      border: props.slot?.id === selectedSlot.id ? '5px solid #6991d6' : '2px solid black',
      position: 'relative',
      cursor: 'pointer',
      opacity: hover ? 0.5 : 1,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '15px',
    }}>
    {
      !props.slot ? (
        <div style={{

        }}>
          <Typography align="center" color="black" variant="h4" fontWeight={"bold"}>
            +
          </Typography>
          <Typography color="black" align="center">
            Click to add
          </Typography>
        </div>
      ) : null
    }
    {
      props.slot && action === 'edit' ? (
        <>
          <div
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              if (props.slot) {
                const findSlot = selectedSlotsToPlay.find((item) => {
                  return props.slot?.id === item.id
                })
                let slotsToAdd: IKuroroTeamSlot[] = []
                if (findSlot) {
                  slotsToAdd = selectedSlotsToPlay.filter((item) => {
                    return item.id !== findSlot.id
                  })
                } else {
                  slotsToAdd = [
                    ...selectedSlotsToPlay,
                    props.slot
                  ]
                }
                dispatch(setSelectedSlotsToPlayState({
                  slotsToPlay: slotsToAdd
                }))
              }
            }}
            style={{
              width: "40px",
              height: "40px",
              position: 'absolute',
              top: 0,
              right: 0,
              backgroundColor: selectedSlotsToPlay.find(item => item.id === props.slot?.id) ? theme.palette.primary.main : 'rgb(0, 0, 0)',
              transform: "skew(0deg)",
              borderLeft: '2px solid black',
              borderBottom: '2px solid black',
              borderEndStartRadius: '15px'
            }}

          >

          </div>

          <div
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              if (props.slot) {
                removeSelectedSlot({
                  slotId: props.slot.id ?? 0
                })
              }
            }}
            style={{
              width: "40px",
              height: "40px",
              position: 'absolute',
              bottom: 0,
              left: 0,
              backgroundColor: 'red',
              transform: "skew(0deg)",
              borderLeft: '2px solid black',
              borderBottom: '2px solid black',
              borderEndStartRadius: '15px',
              fontSize: '15px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              fontWeight: 'bold'

            }}
          >
            <RemoveCircleOutlineIcon />
          </div>
        </>

      ) : null
    }


  </div>
}