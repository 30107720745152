import { createTheme } from "@mui/material";

export const themeDefault = createTheme({
  palette: {
    primary: {
      main: "#6991d6",
      "700": "#00ade7"
    },
    secondary: {
      main: '#E7E7E7'
    },
    info: {
      main: "#32363A"
    },
    background: {
      default: '#152034',
      paper: '#152034',
    },
    common: {
      white: '#E7E7E7'
    },
    text: {
      primary: '#E7E7E7',
      disabled: '#4d4d4d',
      secondary: '#E7E7E7'
    }
  },
  typography: {
    body1: {
      color: '#E7E7E7'
    }
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: {
            color: "primary",
            variant: "contained"
          },
          style: {
            color: '#000',
            fontWeight: 'bold'
          },
        },
        {
          props: {
            color: "secondary",
            variant: "contained"

          },
          style: {
            color: '#000',
            fontWeight: 'bold'
          },
        },
        {
          props: {
            color: "error",
            variant: "contained"
          },
          style: {
            color: '#fff',
            fontWeight: 'bold'
          },
        }
      ]
    },
    MuiTextField: {

      styleOverrides: {
        root: {
          '& label': {
            color: 'white',
          },
          '& label.Mui-focused': {
            color: 'white',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: 'yellow',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'white',
            },
            '&:hover fieldset': {
              borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#6f8090',
            },
          }
        }
      },
    },
    MuiCssBaseline: {
      styleOverrides: {

      }
    }
  }
})