import React, { useState } from 'react';
import { Autocomplete, Box, Button, Checkbox, Grid, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { IKuroro } from '../types';
import { atkTypeInfoMap } from './SelectedKuroro';
import { useAppDispatch, useAppSelector } from '../hooks';
import { setSelectedKuroroTeamSlotState } from '../application/team-builder';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
function isArrayContained(subArray: any, mainArray: any) {
  return subArray.every((element: any) => mainArray.includes(element));
}
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export const KuroroPickupList = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch()
  const selectedTeamSlot = useAppSelector((state) => state.teamBuilder.selectedSlot)
  const [kuroroNameFilter, setKuroroNameFilter] = useState('')
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const [types, setSelectedTypes] = useState<string[]>([])
  const kuroros = useAppSelector((state) => state.kuroroWIki.kuroros)
  return <Box sx={{
    padding: isLargeScreen ? '50px' : '5px',
  }}>
    <Grid container spacing={2} >
      <Grid item xs={12}>
        <Box sx={{
          padding: '30px'
        }}>
          <TextField
            color='secondary'
            value={kuroroNameFilter}
            onChange={(e) => {
              setKuroroNameFilter(e.target.value)
            }}
            label={"Kuroro name"}
            placeholder="Type the kuroro's name"
            fullWidth />
          <Box sx={{
            marginTop: '20px'
          }}>

            <Autocomplete
              onChange={(event, newValue) => {
                setSelectedTypes(newValue)
              }}
              multiple
              id="checkboxes-tags-demo"
              options={atkTypeInfoMap.map((item) => {
                return item.type
              })}
              disableCloseOnSelect
              getOptionLabel={(option) => option}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Types filter" placeholder="Types filter" />
              )}
            />
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={3}>
          {
            kuroros.filter((item) => {
              if (kuroroNameFilter === '') {
                return true
              }
              if (item.name.toLowerCase().includes(kuroroNameFilter.toLowerCase())) {
                return true
              }
              return false
            }).filter((item) => {
              if (types.length === 0) return true
              const lowerCaseTypes = types.map((itemTAux) => {
                return itemTAux.toLowerCase()
              })
              const lowerCaseKuroroType = item.types.map((aux) => {
                return aux.toLowerCase()
              })

              return isArrayContained(lowerCaseTypes, lowerCaseKuroroType)

            }).map((item, index) => {
              return <Grid key={index} sx={{
                transition: '0.3s all',
                "&:hover": {
                  opacity: '0.5'
                }
              }} item xs={6} lg={3}>
                <Box sx={{

                }}>
                  <Button fullWidth onClick={(e) => {
                    dispatch(setSelectedKuroroTeamSlotState({
                      kuroroTeamSlot: {
                        ...selectedTeamSlot,
                        kuroro: {
                          id: item.id.toString(),
                          lore: item.lore,
                          name: item.name,
                          types: item.types,
                          status: item.status
                        } as IKuroro
                      }
                    }))
                  }} style={{
                  }}>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignContent: 'center',
                      justifyContent: 'space-between',
                      height: isLargeScreen ? '120px' : '220px',
                      padding: '20px',
                      boxShadow: '10px 10px 5px 5px rgba(0,0,0,0.2)',
                      overflow: 'hidden',
                      width: '100%',
                      borderRadius: '10px',
                      
                      border: selectedTeamSlot.kuroro?.id === item.id.toString() ? `5px solid ${theme.palette.primary.main}`:'1px solid ' + theme.palette.primary.main,
                    }}>
                      <div>
                        <Typography fontWeight={"bold"} align='center'>
                          {item.name}
                        </Typography>
                      </div>
                      <div style={{
                        height: '100%'
                      }}>
                        <img style={{
                          maxWidth: '100%',
                          maxHeight: '100%',
                        }} src={`/kuroro-img/${item.name}.png`} alt="asd" />
                      </div>

                      {/* {
                        item.types.map((typeItem) => {
                          return <Chip
                            style={{
                              background: kuroroTypeColorMap.find((itemColor) => {
                                return itemColor.type.toLowerCase() === typeItem.toLocaleLowerCase()
                              })?.color,
                              color:  `${kuroroTypeColorMap.find((itemColorMap) => {
                                return itemColorMap.type.toLowerCase() === typeItem.toLowerCase()
                              })?.textColor ?? 'white'}`,
                              margin: '10px'
                            }}
                            avatar={<Avatar style={{
                              background: 'black'
                            }} alt="Natacha" src={"/" + typeItem.toLocaleLowerCase() + "-white.webp"} />}
                            label={<Typography fontWeight={"bold"}>
                              {
                                typeItem
                              }
                            </Typography>
                            }
                            variant="outlined"
                          />
                        })
                      } */}

                    </div>
                  </Button>
                </Box>

              </Grid>

            })
          }

        </Grid>
      </Grid>
    </Grid>
  </Box>
}