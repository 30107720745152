import { useCallback } from "react"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { useAuthApi } from "../../infra/auth"
import { resetAuthState, setAuthIsValidState, setAuthTokenReadyState, setRequestNewPasswordFormEmail, setRequestNewPasswordFormErrors, setRequestNewPasswordFormTouched, setResetPasswordFormConfirmPassword, setResetPasswordFormErrors, setResetPasswordFormPassword, setResetPasswordFormTouched, setSignInFormEmail, setSignInFormErrors, setSignInFormPassword, setSignInFormTouched, setTokenState, validateRequestNewPasswordForm, validateResetPasswordForm, validateSignInForm } from "../../application/auth"
import { useUiService } from "../ui"
import { useLocalStorageService } from "../local-storage"
import { useNavigate } from "react-router-dom"
import { useUserApi } from "../../infra/user"
import { setCurrentUserState } from "../../application/user"
import { sha512 } from "js-sha512"
import { jwtDecode } from "jwt-decode"

export const useAuthService = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const signInForm = useAppSelector((state) => state.auth.signInForm)
  const requestNewPasswordForm = useAppSelector((state) => state.auth.requestNewPasswordForm)
  const resetPasswordForm = useAppSelector((state) => state.auth.resetPasswordForm)
  const tokenReady = useAppSelector((state) => state.auth.tokenReady)
  const { setAlert } = useUiService()
  const { setItem, getItem, deleteItem } = useLocalStorageService()

  const { signInApi, requestNewPasswordApi, confirmEmailApi, resetPasswordApi, resendConfirmEmailApi } = useAuthApi()
  const { getUserNicknameApi } = useUserApi()
  const setupSession = useCallback(() => {
    const tokenLocalStorage = getItem({
      key: 'token'
    })
    if (tokenLocalStorage !== '' && tokenLocalStorage !== null && tokenReady) {
      dispatch(setTokenState({
        token: tokenLocalStorage
      }))
      dispatch(setAuthIsValidState({
        isValid: true
      }))
      try {
        const decoded = jwtDecode(tokenLocalStorage);
        if (tokenReady) {

          getUserNicknameApi({
            nickname: decoded.sub ?? ''
          }).then((response) => {
            dispatch(setCurrentUserState({
              user: {
                email: response.email,
                id: response.id.toString(),
                nickname: response.nickname
              }
            }))
          }).catch((err) => {

          })
        }
      } catch (err) {

      }

    }
    dispatch(setAuthTokenReadyState({
      ready: true
    }))
  }, [
    dispatch,
    getItem,
    getUserNicknameApi,
    tokenReady
  ])

  const setSignFormEmail = useCallback((data: {
    email: string
  }) => {
    dispatch(setSignInFormEmail({
      email: data.email
    }))
  }, [
    dispatch,
  ])

  const setSignFormPassword = useCallback((data: {
    password: string
  }) => {
    dispatch(setSignInFormPassword({
      password: data.password
    }))
  }, [dispatch])

  const signIn = useCallback(() => {
    const errors = validateSignInForm(signInForm.fields)
    if (errors.length > 0) {
      dispatch(setSignInFormTouched({
        touched: true
      }))
      dispatch(setSignInFormErrors({
        errors: errors
      }))
    } else {
      signInApi({
        username: signInForm.fields.email,
        password: sha512(signInForm.fields.password)
      }).then((response) => {
        dispatch(setTokenState({
          token: response.token
        }))
        dispatch(setAuthIsValidState({
          isValid: true
        }))
        setItem({
          key: 'token',
          value: response.token
        })
        navigate('/workspace/team-builder')

      }).catch((err) => {
        setAlert({
          message: err.message,
          type: 'error'
        })
      })
    }
  }, [
    dispatch,
    setAlert,
    signInApi,
    signInForm.fields,
    setItem,
    navigate
  ])

  const signOut = useCallback(() => {
    dispatch(resetAuthState())
    deleteItem({
      key: 'token'
    })
    dispatch(setCurrentUserState({
      user: {
        email: '',
        id: '',
        nickname: ''
      }
    }))
    window.location.reload()
  }, [
    deleteItem,
    dispatch
  ])

  const setRequestNewPasswordEmail = useCallback((data: {
    email: string
  }) => {
    dispatch(setRequestNewPasswordFormEmail({
      email: data.email
    }))
  }, [
    dispatch,
  ])

  const requestNewPassword = useCallback(async () => {
    const errors = validateRequestNewPasswordForm({
      email: requestNewPasswordForm.fields.email
    })

    if (errors.length > 0) {
      dispatch(setRequestNewPasswordFormTouched({
        touched: true
      }))
      dispatch(setRequestNewPasswordFormErrors({
        errors: errors
      }))
      return
    } else {
      return requestNewPasswordApi({
        email: requestNewPasswordForm.fields.email
      }).then(() => {
        navigate("/auth/sign-in")
        setAlert({
          message: 'We have sent an email with a link to reset your password.',
          type: 'success'
        })
      })
    }
  }, [
    dispatch,
    navigate,
    requestNewPasswordApi,
    requestNewPasswordForm.fields.email,
    setAlert
  ])


  const confirmEmail = useCallback(async (data: {
    token: string
  }) => {
    return confirmEmailApi({
      token: data.token
    })
  }, [
    confirmEmailApi
  ])

  const resendConfirmEmail = useCallback(async (data: {
    token: string
  }) => {
    return resendConfirmEmailApi({
      token: data.token
    })
  }, [
    resendConfirmEmailApi
  ])


  const setResetPasswordPassword = useCallback((data: {
    password: string
  }) => {
    dispatch(setResetPasswordFormPassword({
      password: data.password
    }))
  }, [
    dispatch,
  ])

  const setResetPasswordConfirmPassword = useCallback((data: {
    confirmPassword: string
  }) => {
    dispatch(setResetPasswordFormConfirmPassword({
      confirmPassword: data.confirmPassword
    }))
  }, [
    dispatch,
  ])

  const resetPassword = useCallback(async (data: {
    token: string
  }) => {
    const errors = validateResetPasswordForm({
      confirmPassword: resetPasswordForm.fields.confirmPassword,
      password: resetPasswordForm.fields.password
    })
    if (errors.length > 0) {
      dispatch(setResetPasswordFormTouched({
        touched: true
      }))
      dispatch(setResetPasswordFormErrors({
        errors: errors
      }))
    } else {
      return resetPasswordApi({
        token: data.token,
        password: sha512(resetPasswordForm.fields.password)
      }).then(() => {
        setAlert({
          message: 'Password updated!',
          type: 'success'
        })
        navigate('/auth/sign-in')
      }).catch((err) => {
        setAlert({
          message: 'Error while updating password',
          type: 'error'
        })
      })
    }
  }, [
    resetPasswordApi,
    resetPasswordForm.fields,
    dispatch,
    setAlert,
    navigate
  ])


  return {
    setSignFormEmail,
    setSignFormPassword,
    signIn,
    setupSection: setupSession,
    signOut,
    setRequestNewPasswordEmail,
    requestNewPassword,
    confirmEmail,
    resetPassword,
    setResetPasswordConfirmPassword,
    setResetPasswordPassword,
    resendConfirmEmail
  }
}