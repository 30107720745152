import { Grid, Box, Button, Chip, Avatar, Typography } from "@mui/material"
import { useState } from "react"
import { MoveListPicker } from "./MoveListPicker"
import { useAppDispatch, useAppSelector } from "../hooks"
import { kuroroTypeColorMap } from "./SelectedKuroro"
import { setSelectedKuroroTeamSlotState } from "../application/team-builder"

export const MovesSection = () => {
  const [open, setOpen] = useState(false)
  const selectedSlot = useAppSelector((state) => state.teamBuilder.selectedSlot)
  const action = useAppSelector((state) => state.teamBuilder.action)
  const [activeSlot, setActiveSlot] = useState<number | undefined>(undefined)
  const dispatch = useAppDispatch()

  return <>
    <MoveListPicker open={open} setOpen={setOpen} />
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box>
          <Grid container justifyContent={"center"} spacing={2}>
            <Grid item >
              <Button
                onClick={(e) => {
                  if (action === 'edit') {
                    dispatch(setSelectedKuroroTeamSlotState({
                      kuroroTeamSlot: {
                        ...selectedSlot,
                        moves: selectedSlot.moves.filter((item) => {
                          return item.move !== selectedSlot.moves[0].move
                        })
                      }
                    }))
                    setOpen(true)
                  } else {
                    setActiveSlot(0)
                  }
                }}
                style={{
                  height: '200px',
                  width: '200px',
                  background: 'black',
                  margin: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  cursor: 'pointer',
                  fontWeight: "bold",
                  border: activeSlot === 0 ? '#6991d6 5px solid' : undefined

                }}>
                <Grid container>
                  <Grid item xs={12}>
                    {
                      selectedSlot.moves[0] !== undefined ? selectedSlot.moves[0]?.move : 'Add Move'
                    }
                  </Grid>
                  <Grid item xs={12}>
                    {
                      selectedSlot.moves[0] !== undefined ? (
                        <>
                          <Chip
                            style={{
                              background: kuroroTypeColorMap.find((itemColor) => {
                                return itemColor.type.toLowerCase() === selectedSlot.moves[0].type.toLocaleLowerCase()
                              })?.color,
                              color: selectedSlot.moves[0].type.toLowerCase() === 'light' ? 'black' : 'white',
                              margin: '10px'
                            }}
                            avatar={<Avatar style={{
                              background: 'black'
                            }} alt="Natacha" src={"/" + selectedSlot.moves[0].type.toLocaleLowerCase() + "-white.webp"} />}
                            label={<Typography fontWeight={"bold"}>
                              {
                                selectedSlot.moves[0].type
                              }
                            </Typography>
                            }
                            variant="outlined"
                          />
                        </>
                      ) : null
                    }
                  </Grid>
                </Grid>


              </Button>
            </Grid>
            <Grid item >
              <Button
                onClick={(e) => {
                  if (action === 'edit') {
                    setOpen(true)
                    dispatch(setSelectedKuroroTeamSlotState({
                      kuroroTeamSlot: {
                        ...selectedSlot,
                        moves: selectedSlot.moves.filter((item) => {
                          return item.move !== selectedSlot.moves[1]?.move
                        })
                      }
                    }))
                  } else {
                    setActiveSlot(1)
                  }
                }}
                style={{
                  height: '200px',
                  width: '200px',
                  background: 'black',
                  margin: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  cursor: 'pointer',
                  fontWeight: "bold",
                  border: activeSlot === 1 ? '#6991d6 5px solid' : undefined

                }}>
                <Grid container>
                  <Grid item xs={12}>
                    {
                      selectedSlot.moves[1] !== undefined ? selectedSlot.moves[1]?.move : 'Add Move'
                    }
                  </Grid>
                  <Grid item xs={12}>
                    {
                      selectedSlot.moves[1] !== undefined ? (
                        <>
                          <Chip
                            style={{
                              background: kuroroTypeColorMap.find((itemColor) => {
                                return itemColor.type.toLowerCase() === selectedSlot.moves[1].type.toLocaleLowerCase()
                              })?.color,
                              color: selectedSlot.moves[1]?.type.toLowerCase() === 'light' ? 'black' : 'white',
                              margin: '10px'
                            }}
                            avatar={<Avatar style={{
                              background: 'black'
                            }} alt="Natacha" src={"/" + selectedSlot.moves[1]?.type.toLocaleLowerCase() + "-white.webp"} />}
                            label={<Typography fontWeight={"bold"}>
                              {
                                selectedSlot.moves[1].type
                              }
                            </Typography>
                            }
                            variant="outlined"
                          />
                        </>
                      ) : null
                    }
                  </Grid>
                </Grid>


              </Button>
            </Grid>
          </Grid>


        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box >
          <Grid container justifyContent={"center"} spacing={2}>
            <Grid item>
              <Button
                onClick={(e) => {
                  if (action === 'edit') {
                    setOpen(true)
                    dispatch(setSelectedKuroroTeamSlotState({
                      kuroroTeamSlot: {
                        ...selectedSlot,
                        moves: selectedSlot.moves.filter((item) => {
                          return item.move !== selectedSlot.moves[2]?.move
                        })
                      }
                    }))
                  } else {
                    setActiveSlot(2)
                  }
                }}
                style={{
                  height: '200px',
                  width: '200px',
                  background: 'black',
                  margin: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  cursor: 'pointer',
                  fontWeight: "bold",
                  border: activeSlot === 2 ? '#6991d6 5px solid' : undefined

                }}>
                <Grid container>
                  <Grid item xs={12}>
                    {
                      selectedSlot.moves[2] !== undefined ? selectedSlot.moves[2]?.move : 'Add Move'
                    }
                  </Grid>
                  <Grid item xs={12}>
                    {
                      selectedSlot.moves[2] !== undefined ? (
                        <>
                          <Chip
                            style={{
                              background: kuroroTypeColorMap.find((itemColor) => {
                                return itemColor.type.toLowerCase() === selectedSlot.moves[2].type.toLocaleLowerCase()
                              })?.color,
                              color: selectedSlot.moves[2]?.type.toLowerCase() === 'light' ? 'black' : 'white',
                              margin: '10px'
                            }}
                            avatar={<Avatar style={{
                              background: 'black'
                            }} alt="Natacha" src={"/" + selectedSlot.moves[2]?.type.toLocaleLowerCase() + "-white.webp"} />}
                            label={<Typography fontWeight={"bold"}>
                              {
                                selectedSlot.moves[2].type
                              }
                            </Typography>
                            }
                            variant="outlined"
                          />
                        </>
                      ) : null
                    }
                  </Grid>
                </Grid>
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={(e) => {
                  if (action === 'edit') {
                    setOpen(true)
                    dispatch(setSelectedKuroroTeamSlotState({
                      kuroroTeamSlot: {
                        ...selectedSlot,
                        moves: selectedSlot.moves.filter((item) => {
                          return item.move !== selectedSlot.moves[3]?.move
                        })
                      }
                    }))
                  } else {
                    setActiveSlot(3)
                  }
                }}
                style={{
                  height: '200px',
                  width: '200px',
                  background: 'black',
                  margin: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  cursor: 'pointer',
                  fontWeight: "bold",
                  border: activeSlot === 3 ? '#6991d6 5px solid' : undefined

                }}>
                <Grid container>
                  <Grid item xs={12}>
                    {
                      selectedSlot.moves[3] !== undefined ? selectedSlot.moves[3]?.move : 'Add Move'
                    }
                  </Grid>
                  <Grid item xs={12}>
                    {
                      selectedSlot.moves[3] !== undefined ? (
                        <>
                          <Chip
                            style={{
                              background: kuroroTypeColorMap.find((itemColor) => {
                                return itemColor.type.toLowerCase() === selectedSlot.moves[3].type.toLocaleLowerCase()
                              })?.color,
                              color: selectedSlot.moves[3]?.type.toLowerCase() === 'light' ? 'black' : 'white',
                              margin: '10px'
                            }}
                            avatar={<Avatar style={{
                              background: 'black'
                            }} alt="Natacha" src={"/" + selectedSlot.moves[3]?.type.toLocaleLowerCase() + "-white.webp"} />}
                            label={<Typography fontWeight={"bold"}>
                              {
                                selectedSlot.moves[3].type
                              }
                            </Typography>
                            }
                            variant="outlined"
                          />
                        </>
                      ) : null
                    }
                  </Grid>
                </Grid>


              </Button>
            </Grid>
          </Grid>


        </Box>
      </Grid>
    </Grid>
  </>
}