import axios from "axios"
import { useMemo } from "react"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { resetAuthState } from "../../application/auth"

export const useAxiosInstance = () => {
  const token = useAppSelector((state) => state.auth.token)
  const dispatch = useAppDispatch()

  const axiosPublicInstance = useMemo(() => {
    return axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL
    })
  }, [])

  const axiosPrivateInstance = useMemo(() => {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          window.localStorage.removeItem('token')
          dispatch(resetAuthState())
        }
        return Promise.reject(error);
      }
    );

    return instance

  }, [
    token,
    dispatch
  ])

  return {
    axiosPublicInstance,
    axiosPrivateInstance
  }
}