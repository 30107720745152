import React from 'react';
import { atkTypeInfoMap, kuroroTypeColorMap } from './SelectedKuroro';
import { Chip, Avatar, Typography } from '@mui/material';




export const TypesDoubleEntryTable: React.FC = () => {
  return (
    <table>
      <thead>
        <tr>
          <th></th>
          {atkTypeInfoMap.map(({ type }) => (
            <th key={type}>
              <Chip
                style={{
                  background: kuroroTypeColorMap.find((itemColor) => {
                    return itemColor.type.toLowerCase() === type.toLocaleLowerCase()
                  })?.color,
                  color: type.toLowerCase() === 'light' ? 'black' : 'white',
                  margin: '10px'
                }}
                avatar={<Avatar style={{
                  background: 'black'
                }} alt="Natacha" src={"/" + type.toLocaleLowerCase() + "-white.webp"} />}
                label={<Typography fontWeight={"bold"}>
                  {

                    type
                  }
                </Typography>
                }
                variant="outlined"
              />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {atkTypeInfoMap.map((atkTypeInfo) => (
          <tr key={atkTypeInfo.type}>
            <td>
              <Chip
                style={{
                  background: kuroroTypeColorMap.find((itemColor) => {
                    return itemColor.type.toLowerCase() === atkTypeInfo.type.toLocaleLowerCase()
                  })?.color,
                  color: atkTypeInfo.type.toLowerCase() === 'light' ? 'black' : 'white',
                  margin: '10px'
                }}
                avatar={<Avatar style={{
                  background: 'black'
                }} alt="Natacha" src={"/" + atkTypeInfo.type.toLocaleLowerCase() + "-white.webp"} />}
                label={<Typography fontWeight={"bold"}>
                  {
                    atkTypeInfo.type
                  }
                </Typography>
                }
                variant="outlined"
              />
            </td>
            {atkTypeInfo.interactions.map((interaction) => {
              let color = undefined
              if (interaction.defValue  > 1) {
                color = 'rgba(0,255,93,1)'
              } else if (interaction.defValue  === 1) {
                color = undefined
              } else {
                color = 'red'
              }
           
              return (
                <td key={interaction.type}>
                  <Typography align='center' fontWeight={"bold"} style={{
                    color: color
                  }}>
                    {interaction.defValue}
                  </Typography>
                </td>
              )
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};