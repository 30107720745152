import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

import MobileDrawer from './MobileDrawer';
import { useAppSelector } from '../../../hooks';
import { Button, Grid, useMediaQuery, useTheme } from '@mui/material';
import { AvatarMenuButton } from './AvatarMenuButton';
import { useNavigate } from 'react-router-dom';

export const MobileAppBar = () => {
  const currentUser = useAppSelector((state) => state.user.currentUser)
  const theme = useTheme()
  const upMd = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate()


  return (
    <>
      <Box sx={{ flexGrow: 1, position: 'fixed', top: 0, right: 0, left: 0, zIndex: 100 }}>
        <AppBar color='info' position="static">
          <Toolbar style={{
            position: 'relative',
            padding: '0 20px'
          }}>
            <MobileDrawer />
            <div style={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center'
            }}>
              <img style={{
                width: '100px',
              }} src="/stratzord-logo-horizontal.png" alt="Stratzord logo" />
            </div>
            {currentUser.id ? (
              <div>
                <AvatarMenuButton />
              </div>
            ) :
              <>
                <Box sx={{
                }}>
                  <Grid container style={{
                    padding: '5px'
                  }} spacing={1} justifyContent={upMd ? undefined : 'flex-end'} >
                    <Grid item>
                      <Button color='secondary' variant='contained' onClick={() => {
                        navigate('/auth/sign-in')
                      }}>
                        Sign in
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button color='primary' variant='contained' onClick={() => {
                        navigate('/auth/sign-up')
                      }}>
                        Sign up
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </>

            }
          </Toolbar>
        </AppBar>
      </Box>
      <div style={{
        marginTop: '60px'
      }}></div>
    </>
  );
}
