import { Box, Button, Container, Dialog, DialogActions, DialogTitle, Grid, Typography, useMediaQuery, useTheme } from "@mui/material"
import { NewTeamDialog } from "./NewTeamDialog";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { KuroroTeamSlot } from "../../../components/KuroroTeamSlot";
import { useEffect } from "react";
import { setAction, setOpenTypesChartState } from "../../../application/team-builder";
import { TypesDoubleEntryTable } from "../../../components/TypesDoubleEntryTable";
import './TeamBuilderHomePage.css'
import TableChartIcon from '@mui/icons-material/TableChart';
import { TeamsOfTheWeek } from "./TeamOfTheWeek";
import { useTeamBuilderService } from "../../../services/team-builder/useTeamBuilderService";
import { SettingsDialog } from "../../../components/SettingsDialog";
export const TeamBuilderHomePage = () => {
  const myTeams = useAppSelector((state) => state.teamBuilder.myTeams)
  const openTypesChart = useAppSelector((state) => state.teamBuilder.openTypesChart)

  const { setMyTeams } = useTeamBuilderService()
  const theme = useTheme()
  const isLg = useMediaQuery(theme.breakpoints.up('md'))

  const dispatch = useAppDispatch()
  useEffect(() => {
    setMyTeams()
    dispatch(setAction({
      action: 'list'
    }))
  }, [setMyTeams, dispatch])


  return <>
    <Container component="main" maxWidth="md">
      <Grid container spacing={5} justifyContent={"center"}>
        <Grid item xs={12} >
          <TeamsOfTheWeek />
        </Grid>
        <Grid item xs={12} >
          <Grid container spacing={3}>
            <Grid item>
              <NewTeamDialog />
            </Grid>
            <Grid item>
              <Button variant="contained" color="secondary" onClick={(e) => {
                dispatch(setOpenTypesChartState({
                  openTypesChart: true
                }))
              }} endIcon={<TableChartIcon />}>
                View Type Chart
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} >
          <Typography variant="h5" color="white" fontWeight={"bold"}>
            My Teams
          </Typography>
        </Grid>
        <Grid item xs={12}  >
          <Grid container spacing={5}>
            {
              myTeams.map((item, index) => {
                return <>
                  <Grid item xs={12} key={index} style={{
                    marginTop: isLg ? undefined : '50px'
                  }}>
                    <KuroroTeamSlot team={item} />

                  </Grid>
                </>
              })
            }
          </Grid>

        </Grid >
      </Grid>
      <Dialog
        fullWidth
        maxWidth="xl"
        onClose={() => {
          dispatch(setOpenTypesChartState({
            openTypesChart: false
          }))
        }} open={openTypesChart}>
        <DialogTitle>Types Interaction Table</DialogTitle>
        <Box p={3}>
          <TypesDoubleEntryTable />
        </Box>
        <DialogActions style={{
          justifyContent: 'center'
        }}>
          <Button onClick={(e) => {
            dispatch(setOpenTypesChartState({
              openTypesChart: false
            }))
          }} color="error" variant="contained">
            <Typography fontWeight={"bold"}>
              Close
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
      <SettingsDialog />
    </Container>

  </>
}