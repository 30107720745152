import React, { useEffect } from 'react';
import { MainRouter } from './router/main/MainRouter';

import { ThemeProvider } from '@mui/material';
import { themeDefault } from './theme/DefaultTheme';
import { CustomScrollbar } from './components/CustomScrollbar';

import ReactGA from "react-ga4";
import { AlertContainer } from './components/alert-container';
import { useKuroroWikiService } from './services/kuroro-wiki';
import { GlobalStyles } from '@mui/material';

ReactGA.initialize(process.env.REACT_APP_GOOGLETAG ?? "");

function App() {
  const { setKuroros, setMoves } = useKuroroWikiService()
  useEffect(() => {
    setKuroros()
    setMoves()
  }, [
    setKuroros,
    setMoves
  ])


  return (
    <>
      <ThemeProvider theme={themeDefault}>
        <GlobalStyles styles={{
          body: {
            backgroundColor: themeDefault.palette.background.default
          }
        }} />
        <CustomScrollbar />
        <MainRouter />
        <AlertContainer />
      </ThemeProvider>

    </>
  );
}

export default App;
