import { useCallback } from "react"
import { useKuroroWikiApi } from "../../infra/kuroro-wiki"
import { useLocalStorageService } from "../local-storage"
import { useAppDispatch } from "../../hooks"
import { setKurorosState, setMovesState } from "../../application/kuroro-wiki"
import { IKuroro } from "../../types"
import { IKuroroMove } from "../../types/IKuroroMove"

export const useKuroroWikiService = () => {
  const dispatch = useAppDispatch()
  const { getKurorosApi, getMovesApi } = useKuroroWikiApi()
  const { getItem, setItem } = useLocalStorageService()
  const setKuroros = useCallback(() => {
    const kurorosLocalStorage = getItem({
      key: 'kuroros'
    })
    if (kurorosLocalStorage) {
      const casted = JSON.parse(kurorosLocalStorage)
      dispatch(setKurorosState({
        kuroros: casted as IKuroro[]
      }))
    } else {
      getKurorosApi().then((response) => {
        dispatch(setKurorosState({
          kuroros: response
        }))
        
        setItem({
          key: 'kuroros',
          value: JSON.stringify(response)
        })
      })
    }
  }, [
    dispatch,
    getItem,
    setItem,
    getKurorosApi
  ])


  const setMoves = useCallback(() => {
    const movesLocalStorage = getItem({
      key: 'moves'
    })
    if (movesLocalStorage) {
      const casted = JSON.parse(movesLocalStorage)
      dispatch(setMovesState({
        moves: casted as IKuroroMove[]
      }))
    } else {
      getMovesApi().then((response) => {
        dispatch(setMovesState({
          moves: response
        }))
        
        setItem({
          key: 'moves',
          value: JSON.stringify(response)
        })
      })
    }
  }, [
    setItem,
    getItem,
    dispatch,
    getMovesApi
  ])

  return {
    setKuroros,
    setMoves
  }
}