import { useCallback } from "react"
import { useAxiosInstance } from "../axios-instance"

export interface ISignInModelApi {
  username: string
  password: string
}

export const useAuthApi = () => {
  const { axiosPublicInstance } = useAxiosInstance()
  const signInApi = useCallback(async (data: ISignInModelApi) => {
    try {
      const response = await axiosPublicInstance.post<{
        jwt: string
      }>("/auth/login", data)

      return {
        token: response.data.jwt
      }

    } catch (err: any) {
      console.log(err.response)
      throw new Error(err.response.data.message)
    }
  }, [
    axiosPublicInstance
  ])

  const requestNewPasswordApi = useCallback(async (data: {
    email: string
  }): Promise<void> => {
    try {
      await axiosPublicInstance.post("/auth/resetPassword", {
        email: data.email
      })
    } catch (err: any) {
      throw new Error(err.response.data.message)
    }

  }, [
    axiosPublicInstance
  ])

  const resetPasswordApi = useCallback(async (data: {
    password: string,
    token: string
  }): Promise<void> => {
    try {
      await axiosPublicInstance.post("/auth/reset", {
        token: data.token,
        password: data.password
      })
    } catch (err: any) {
      throw new Error(err.response.data.message)
    }

  }, [
    axiosPublicInstance
  ])


  const confirmEmailApi = useCallback(async (data: {
    token: string
  }): Promise<void> => {
    try {
      await axiosPublicInstance.post('/auth/confirm', {
        token: data.token
      })
      return
    } catch (err) {
      throw err
    }
  }, [
    axiosPublicInstance
  ])

  const resendConfirmEmailApi = useCallback(async (data: {
    token: string
  }): Promise<void> => {
    try {
      await axiosPublicInstance.post('/auth/resend', {
        token: data.token
      })
      return
    } catch (err: any) {
      throw new Error(`${err?.response?.data?.message}`)
    }
  }, [
    axiosPublicInstance
  ])


  return {
    signInApi,
    requestNewPasswordApi,
    confirmEmailApi,
    resetPasswordApi,
    resendConfirmEmailApi
  }
}