import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip as ChartTooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import { atkTypeInfoMap, kuroroTypeColorMap } from './SelectedKuroro';
import { Grid, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import { useAppSelector } from '../hooks';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  ChartTooltip,
  Legend
);




export function TypeInteractionsSection() {
  const selectedSlot = useAppSelector((state) => state.teamBuilder.selectedSlot)
  const [data, setData] = useState({
    labels: ['Fire', 'Water', 'Plant', 'Light', 'Dark', 'Spirit', 'Electric', 'Wind', 'Earth', 'Corrosion', 'Combat', 'Metal'],
    datasets: [
      {
        label: 'StrongAgainst',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'black',
        borderWidth: 1

      },
    ],
  })

  const [dataWeak, setDataWeak] = useState({
    labels: ['Fire', 'Water', 'Plant', 'Light', 'Dark', 'Spirit', 'Electric', 'Wind', 'Earth', 'Corrosion', 'Combat', 'Metal'],
    datasets: [
      {
        label: '# of Votes',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'black',
        borderWidth: 1,
      },
    ],
  })

  useEffect(() => {
    const dataSets: any[] = []
    const weakDataSets: any[] = []

    selectedSlot.kuroro?.types.forEach((item, index) => {
      const interactions = atkTypeInfoMap.find((itemInteract) => {
        return item.toLocaleLowerCase() === itemInteract.type.toLocaleLowerCase()
      })
      const attackPages: number[] = []
      const defensePages: number[] = []
      interactions?.interactions.forEach((interaction, index) => {
        attackPages.push(interaction.atkValue)
        defensePages.push(interaction.defValue)
      })
      const color = kuroroTypeColorMap.find((typeColor) => {
        return item.toLocaleLowerCase() === typeColor.type.toLowerCase()
      })?.color
      dataSets.push({
        label: item,
        data: attackPages,
        backgroundColor: color,

        borderColor: 'black',
        borderWidth: 1
      })

      weakDataSets.push({
        label: item,
        data: defensePages,
        backgroundColor: color,
        borderColor: 'black',
        borderWidth: 1
      })
    })

    setData({
      labels: ['Fire', 'Water', 'Plant', 'Light', 'Dark', 'Spirit', 'Electric', 'Wind', 'Earth', 'Corrosion', 'Combat', 'Metal'],
      datasets: dataSets
    })

    setDataWeak({
      labels: ['Fire', 'Water', 'Plant', 'Light', 'Dark', 'Spirit', 'Electric', 'Wind', 'Earth', 'Corrosion', 'Combat', 'Metal'],
      datasets: weakDataSets
    })
  }, [selectedSlot.kuroro])


  return <>
    <div style={{
      width: '100%'
    }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '20px',
            marginBottom: '20px'
          }}>
            <Typography variant="h5" align="center" color="primary" fontWeight={"bold"}>
              Attacking
            </Typography>
            <Tooltip title={<>
              <Typography>
                Interaction between your Kuroro's STAB moves and the types present in the game. Keep in mind that when your Kuroro attacks, the type that matters is the move's type.
              </Typography>
            </>}>
              <InfoIcon color="primary" style={{
                marginLeft: '10px'
              }} />
            </Tooltip>
          </div>
          <Radar data={{
            ...data,
          }}
            options={{
              scales: {
                r: {
                  pointLabels: {
                    display: true, // Hides the labels around the radar chart,,
                    color: '#fff'
                  },
                  ticks: {
                    display: true,
                    precision: 0,
                    color: "#000",
                    padding: 30,
                    z: 9000,
                    font: {
                      size: 15
                    }
                  },
                  grid: {
                    color: '#fff',
                  },
                },
              },
              color: '#fff'
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '20px',
            marginBottom: '20px'
          }}>
            <Typography variant="h5" align="center" color="primary" fontWeight={"bold"}>
              Defending
            </Typography>
            <Tooltip title={<>
              <Typography>
                Type Interactions when your Kuroro is receiving an attack. Always multiply the damage modifiers of both types of your Kuroro against the type of the enemy Kuroro's attack.
              </Typography>
              <Typography style={{
                marginTop: '10px'
              }}>
                Example: If both elements of your Kuroro are super effective (2x damage), your Kuroro will take 4x
              </Typography>
            </>}>
              <InfoIcon color="primary" style={{
                marginLeft: '10px'
              }} />
            </Tooltip>
          </div>
          <Radar data={{
            ...dataWeak
          }}
            options={{
              scales: {
                r: {
                  pointLabels: {
                    display: true, // Hides the labels around the radar chart,,
                    color: '#fff'
                  },
                  ticks: {
                    display: true,
                    precision: 0,
                    color: "#000",
                    padding: 30,
                    z: 9000,
                    font: {
                      size: 15
                    }
                  },
                  grid: {
                    color: '#fff',
                  },
                },
              },
              color: '#fff'
            }}
          />
        </Grid>
      </Grid>
    </div>

  </>
}
