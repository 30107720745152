import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import { WorkspaceSectionLayout } from "../../components/layouts/PrivateSectionLayout"
import { TeamBuilderHomePage } from "../../pages/workspace/team-builder/TeamBuilderHomePage"
import { CommunityHomePage } from "../../pages/workspace/community/CommunityHomePage"
import { PlayKuroroBeastsHomePage } from "../../pages/workspace/play-kuroro-beasts/PlayKuroroBeastsHomePage"
import { ProfileHomePage } from "../../pages/workspace/profile/ProfileHomePage"
import { PasswordResetPage, RequestNewPasswordPage, SignInPage, SignUpPage } from "../../pages/auth"
import { KuroroListPage } from "../../pages/workspace/kuroro-list"
import { PrivateRoute } from "../private-route"
import { EmailConfirmationPage } from "../../pages/auth/email-confirmation"
import { EditTeamDialog } from "../../pages/workspace/team-builder/EditTeamDialog"
import { TeamNotFoundPage } from "../../pages/workspace/team-builder/TeamNotFound"



export const MainRouter = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/workspace/team-builder" />} />
          <Route path="workspace" element={<WorkspaceSectionLayout />}>
            <Route path="team-builder" element={
              <TeamBuilderHomePage />
            } />
            <Route path="team-builder/team-not-found" element={
              <TeamNotFoundPage />
            } />
            <Route path="team-builder/:teamId" element={
              <EditTeamDialog />
            } />
            <Route path="community" element={
              <CommunityHomePage />
            } />
            <Route path="play-kuroro-beasts" element={
              <PlayKuroroBeastsHomePage />
            } />
            <Route path="profile" element={
              <PrivateRoute>
                <ProfileHomePage />
              </PrivateRoute>
            } />
            <Route path="kuroro-wiki" element={
              <KuroroListPage />
            } />
          </Route>
          <Route path="auth">
            <Route path="sign-up" element={<SignUpPage />} />
            <Route path="sign-in" element={<SignInPage />} />
            <Route path="reset" element={<PasswordResetPage />} />
            <Route path="request-new-password" element={<RequestNewPasswordPage />} />
            <Route path="confirm"element={<EmailConfirmationPage />} />

          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </>

  )
}