import { Avatar, Box, Chip, Grid, Paper, Tab, Typography, useMediaQuery, useTheme } from '@mui/material'
import { IKuroroType } from '../types/IKuroroType'
import { useAppSelector } from '../hooks'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import React from 'react'
import { StatusSection } from './StatusSection'
import { MovesSection } from './MovesSection'
import { TypeInteractionsSection } from './TypeInteractionsSection'

type KuroroTypeColorMap = {
  type: IKuroroType
  color: string
  textColor: string
}

type AtkTypeInfo = {
  type: IKuroroType,
  interactions: {
    type: IKuroroType,
    atkValue: number,
    defValue: number
  }[]
}

export const atkTypeInfoMap: AtkTypeInfo[] = [
  {
    type: 'Fire',
    interactions: [
      {
        type: 'Fire',
        atkValue: 0.5,
        defValue: 0.5
      },
      {
        type: 'Water',
        atkValue: 0.5,
        defValue: 2
      },
      {
        type: 'Plant',
        atkValue: 2,
        defValue: 0.5
      },
      {
        type: 'Light',
        atkValue: 0.5,
        defValue: 2
      },
      {
        type: 'Dark',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Spirit',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Electric',
        atkValue: 2,
        defValue: 0.5
      },
      {
        type: 'Wind',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Earth',
        atkValue: 0.5,
        defValue: 2
      },
      {
        type: 'Corrosion',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Combat',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Metal',
        atkValue: 2,
        defValue: 0.5
      }
    ]
  },
  {
    type: 'Water',
    interactions: [
      {
        type: 'Fire',
        atkValue: 2,
        defValue: 0.5
      },
      {
        type: 'Water',
        atkValue: 0.5,
        defValue: 0.5
      },
      {
        type: 'Plant',
        atkValue: 0.5,
        defValue: 2
      },
      {
        type: 'Light',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Dark',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Spirit',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Electric',
        atkValue: 1,
        defValue: 2
      },
      {
        type: 'Wind',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Earth',
        atkValue: 2,
        defValue: 0.5
      },
      {
        type: 'Corrosion',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Combat',
        atkValue: 1,
        defValue: 2
      },
      {
        type: 'Metal',
        atkValue: 1,
        defValue: 1
      }
    ]
  },
  {
    type: 'Plant',
    interactions: [
      {
        type: 'Fire',
        atkValue: 0.5,
        defValue: 2
      },
      {
        type: 'Water',
        atkValue: 2,
        defValue: 0.5
      },
      {
        type: 'Plant',
        atkValue: 0.5,
        defValue: 0.5
      },
      {
        type: 'Light',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Dark',
        atkValue: 2,
        defValue: 0.5
      },
      {
        type: 'Spirit',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Electric',
        atkValue: 1,
        defValue: 0.5
      },
      {
        type: 'Wind',
        atkValue: 0.5,
        defValue: 2
      },
      {
        type: 'Earth',
        atkValue: 2,
        defValue: 0.5
      },
      {
        type: 'Corrosion',
        atkValue: 0.5,
        defValue: 2
      },
      {
        type: 'Combat',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Metal',
        atkValue: 0.5,
        defValue: 1
      }
    ]
  },
  {
    type: 'Light',
    interactions: [
      {
        type: 'Fire',
        atkValue: 2,
        defValue: 0.5
      },
      {
        type: 'Water',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Plant',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Light',
        atkValue: 0.5,
        defValue: 0.5
      },
      {
        type: 'Dark',
        atkValue: 2,
        defValue: 0.5
      },
      {
        type: 'Spirit',
        atkValue: 0.5,
        defValue: 2
      },
      {
        type: 'Electric',
        atkValue: 0.5,
        defValue: 2
      },
      {
        type: 'Wind',
        atkValue: 2,
        defValue: 1
      },
      {
        type: 'Earth',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Corrosion',
        atkValue: 1,
        defValue: 2
      },
      {
        type: 'Combat',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Metal',
        atkValue: 1,
        defValue: 1
      }
    ]
  },
  {
    type: 'Dark',
    interactions: [
      {
        type: 'Fire',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Water',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Plant',
        atkValue: 0.5,
        defValue: 2
      },
      {
        type: 'Light',
        atkValue: 0.5,
        defValue: 2
      },
      {
        type: 'Dark',
        atkValue: 0.5,
        defValue: 0.5
      },
      {
        type: 'Spirit',
        atkValue: 2,
        defValue: 0.5
      },
      {
        type: 'Electric',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Wind',
        atkValue: 2,
        defValue: 1
      },
      {
        type: 'Earth',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Corrosion',
        atkValue: 2,
        defValue: 0.5
      },
      {
        type: 'Combat',
        atkValue: 1,
        defValue: 2
      },
      {
        type: 'Metal',
        atkValue: 1,
        defValue: 1
      }
    ]
  },
  {
    type: 'Spirit',
    interactions: [
      {
        type: 'Fire',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Water',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Plant',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Light',
        atkValue: 2,
        defValue: 0.5
      },
      {
        type: 'Dark',
        atkValue: 0.5,
        defValue: 2
      },
      {
        type: 'Spirit',
        atkValue: 0.5,
        defValue: 0.5
      },
      {
        type: 'Electric',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Wind',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Earth',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Corrosion',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Combat',
        atkValue: 2,
        defValue: 0.5
      },
      {
        type: 'Metal',
        atkValue: 1,
        defValue: 2
      }
    ]
  },
  {
    type: 'Electric',
    interactions: [
      {
        type: 'Fire',
        atkValue: 0.5,
        defValue: 2
      },
      {
        type: 'Water',
        atkValue: 2,
        defValue: 1
      },
      {
        type: 'Plant',
        atkValue: 0.5,
        defValue: 1
      },
      {
        type: 'Light',
        atkValue: 2,
        defValue: 0.5
      },
      {
        type: 'Dark',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Spirit',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Electric',
        atkValue: 0.5,
        defValue: 0.5
      },
      {
        type: 'Wind',
        atkValue: 2,
        defValue: 0
      },
      {
        type: 'Earth',
        atkValue: 0,
        defValue: 2
      },
      {
        type: 'Corrosion',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Combat',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Metal',
        atkValue: 1,
        defValue: 0.5
      }
    ]
  },
  {
    type: 'Wind',
    interactions: [
      {
        type: 'Fire',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Water',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Plant',
        atkValue: 2,
        defValue: 0.5
      },
      {
        type: 'Light',
        atkValue: 1,
        defValue: 2
      },
      {
        type: 'Dark',
        atkValue: 1,
        defValue: 2
      },
      {
        type: 'Spirit',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Electric',
        atkValue: 0,
        defValue: 2
      },
      {
        type: 'Wind',
        atkValue: 0.5,
        defValue: 0.5
      },
      {
        type: 'Earth',
        atkValue: 2,
        defValue: 0
      },
      {
        type: 'Corrosion',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Combat',
        atkValue: 2,
        defValue: 0.5
      },
      {
        type: 'Metal',
        atkValue: 0.5,
        defValue: 1
      }
    ]
  },
  {
    type: 'Earth',
    interactions: [
      {
        type: 'Fire',
        atkValue: 2,
        defValue: 0.5
      },
      {
        type: 'Water',
        atkValue: 0.5,
        defValue: 2
      },
      {
        type: 'Plant',
        atkValue: 0.5,
        defValue: 2
      },
      {
        type: 'Light',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Dark',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Spirit',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Electric',
        atkValue: 2,
        defValue: 0
      },
      {
        type: 'Wind',
        atkValue: 0,
        defValue: 2
      },
      {
        type: 'Earth',
        atkValue: 0.5,
        defValue: 0.5
      },
      {
        type: 'Corrosion',
        atkValue: 2,
        defValue: 0.5
      },
      {
        type: 'Combat',
        atkValue: 0.5,
        defValue: 2
      },
      {
        type: 'Metal',
        atkValue: 1,
        defValue: 1
      }
    ]
  },
  {
    type: 'Corrosion',
    interactions: [
      {
        type: 'Fire',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Water',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Plant',
        atkValue: 2,
        defValue: 0.5
      },
      {
        type: 'Light',
        atkValue: 2,
        defValue: 1
      },
      {
        type: 'Dark',
        atkValue: 0.5,
        defValue: 2
      },
      {
        type: 'Spirit',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Electric',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Wind',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Earth',
        atkValue: 0.5,
        defValue: 2
      },
      {
        type: 'Corrosion',
        atkValue: 0.5,
        defValue: 0.5
      },
      {
        type: 'Combat',
        atkValue: 0.5,
        defValue: 2
      },
      {
        type: 'Metal',
        atkValue: 2,
        defValue: 0.5
      }
    ]
  },
  {
    type: 'Combat',
    interactions: [
      {
        type: 'Fire',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Water',
        atkValue: 2,
        defValue: 1
      },
      {
        type: 'Plant',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Light',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Dark',
        atkValue: 2,
        defValue: 1
      },
      {
        type: 'Spirit',
        atkValue: 0.5,
        defValue: 2
      },
      {
        type: 'Electric',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Wind',
        atkValue: 0.5,
        defValue: 2
      },
      {
        type: 'Earth',
        atkValue: 2,
        defValue: 1
      },
      {
        type: 'Corrosion',
        atkValue: 2,
        defValue: 0.5
      },
      {
        type: 'Combat',
        atkValue: 0.5,
        defValue: 0.5
      },
      {
        type: 'Metal',
        atkValue: 0.5,
        defValue: 2
      }
    ]
  },
  {
    type: 'Metal',
    interactions: [
      {
        type: 'Fire',
        atkValue: 0.5,
        defValue: 2
      },
      {
        type: 'Water',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Plant',
        atkValue: 1,
        defValue: 0.5
      },
      {
        type: 'Light',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Dark',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Spirit',
        atkValue: 2,
        defValue: 1
      },
      {
        type: 'Electric',
        atkValue: 0.5,
        defValue: 1
      },
      {
        type: 'Wind',
        atkValue: 1,
        defValue: 0.5
      },
      {
        type: 'Earth',
        atkValue: 1,
        defValue: 1
      },
      {
        type: 'Corrosion',
        atkValue: 0.5,
        defValue: 2
      },
      {
        type: 'Combat',
        atkValue: 2,
        defValue: 0.5
      },
      {
        type: 'Metal',
        atkValue: 0.5,
        defValue: 0.5
      }
    ]
  }
]

export const kuroroTypeColorMap: KuroroTypeColorMap[] = [
  {
    type: 'Combat',
    color: 'rgba(153,45,31,0.7)',
    textColor: 'white'
  },
  {
    type: 'Corrosion',
    color: 'rgba(115,112,166,0.7)',
    textColor: 'white'

  },
  {
    type: 'Dark',
    color: 'rgba(89,79,64,0.7)',
    textColor: 'white',

  },
  {
    type: 'Earth',
    color: 'rgba(133,77,41,0.7)',
    textColor: 'white'

  },
  {
    type: 'Electric',
    color: 'rgba(219,184,13,0.7)',
    textColor: 'white'

  },
  {
    type: 'Fire',
    color: 'rgba(201,107,23,0.7)',
    textColor: 'white'

  },
  {
    type: 'Plant',
    color: 'rgba(143,158,51,0.7)',
    textColor: 'white'

  },
  {
    type: 'Light',
    color: 'rgba(209,212,212,0.7)',
    textColor: 'black'

  },
  {
    type: 'Metal',
    color: 'rgba(112,130,135,0.7)',
    textColor: 'white'


  },
  {
    type: 'Spirit',
    color: 'rgba(168,158,133,0.7)',
    textColor: 'white'

  },
  {
    type: 'Water',
    color: 'rgba(15,123,150,0.7)',
    textColor: 'white'

  },
  {
    type: 'Wind',
    color: 'rgba(130,168,171,0.7)',
    textColor: 'black'

  }
]

export const SelectedKuroro = () => {
  const theme = useTheme();
  const selectedSlot = useAppSelector((state) => state.teamBuilder.selectedSlot)
  const action = useAppSelector((state) => state.teamBuilder.action)
  const [value, setValue] = React.useState('1');
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const isLg = useMediaQuery(theme.breakpoints.up('md'));
  return <>

      <Grid container spacing={3} alignItems={"center"} >
        <Grid item xs={12}>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Grid container justifyContent={"space-between"} wrap='nowrap' spacing={3} style={{
                  overflowX: 'auto',
                  padding: '10px'
                }}>
                  <Grid item>
                    <TabList indicatorColor='secondary' textColor='secondary' onChange={handleChange} aria-label="lab API tabs example">
                      <Tab label="General" value="1" />
                      <Tab disabled={!selectedSlot.kuroro} label="Status" value="2" />
                      {
                        action !== 'list' ? (
                          <Tab disabled={!selectedSlot.kuroro} label="Moves" value="3" />
                        ) : null
                      }
                      <Tab disabled={!selectedSlot.kuroro} label="Type interactions" value="4" />
                    </TabList>
                  </Grid>
                </Grid>
              </Box>
              <TabPanel value="1">
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <div style={{
                      display: 'flex',
                      justifyContent: "center"
                    }}>
                      <img style={{
                        width: isLg ? '60%' : '100%'
                      }} src={selectedSlot.kuroro ? `/kuroro-img/${selectedSlot.kuroro?.name}.png` : '/StratzordUnknown.png'} alt="Kuroro slot" />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Paper elevation={3} style={{
                      padding: '30px'
                    }}>
                      <Grid container>
                        <Grid item xs={12}>
                          <div style={{
                            display: 'flex',
                            alignItems: 'center'
                          }}>
                            <Typography style={{
                              marginRight: '10px'
                            }} variant="h4" fontWeight={"bold"}>
                              {selectedSlot.kuroro?.name}
                            </Typography>
                          </div>
                          {
                            selectedSlot.kuroro?.types.map((item, index) => {
                              return <>
                                <Chip
                                  style={{
                                    background: kuroroTypeColorMap.find((itemColor) => {
                                      return itemColor.type.toLowerCase() === item.toLocaleLowerCase()
                                    })?.color,
                                    color: item.toLowerCase() === 'light' ? 'black' : 'white',
                                    margin: '10px'
                                  }}
                                  avatar={<Avatar style={{
                                    background: 'black'
                                  }} alt="Natacha" src={"/" + item.toLocaleLowerCase() + "-white.webp"} />}
                                  label={<Typography fontWeight={"bold"}>
                                    {
                                      item
                                    }
                                  </Typography>
                                  }
                                  variant="outlined"
                                />
                              </>
                            })
                          }
                          <Typography>
                            {selectedSlot.kuroro?.lore}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>

                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value="2">
                <StatusSection />
              </TabPanel>
              <TabPanel value="3">
                <MovesSection />
              </TabPanel>
              <TabPanel value="4">
                <TypeInteractionsSection />
              </TabPanel>
            </TabContext>
          </Box>

        </Grid>
      </Grid>
  </>
}