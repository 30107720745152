import { useLocation, useNavigate } from "react-router-dom"
import '../PrivateSectionLayout.css'
import { Box, Button, Grid, useMediaQuery, useTheme } from "@mui/material"
import { useAppSelector } from "../../../hooks"
import { MobileAppBar } from "./MobileHeader"
import { AvatarMenuButton } from "./AvatarMenuButton"


const NavLink = (props: {
  path: string,
  text: string,
  onClick?: any
}) => {
  const theme = useTheme()
  const location = useLocation()
  const navigate = useNavigate()
  return <>
    <Button onClick={() => {
      if (props.onClick) {
        props.onClick()
      } else {
        navigate(props.path)
      }
    }} style={{
      background: location.pathname === props.path ? theme.palette.primary.main : undefined,
      color: location.pathname === props.path ? theme.palette.common.black : undefined

    }}>{props.text}</Button>
  </>
}

export const Header = () => {
  const navigate = useNavigate()

  // const currentUser = useAppSelector((state) => state.user.currentUser)
  const currentUser = useAppSelector((state) => state.user.currentUser)
  const theme = useTheme()

  const upMd = useMediaQuery(theme.breakpoints.up('md'));

  return <>
    {
      upMd ? (
        <>
          {
            currentUser.id ? (
              <>
                <AvatarMenuButton />
              </>
            ) : <>
              <Box p={3} sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                zIndex: 500
              }}>
                <Grid container spacing={3}>
                  <Grid item>
                    <Button variant="contained" color="primary" onClick={(e) => {
                      navigate("/auth/sign-in")
                    }}>
                      Sign in
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button onClick={() => {
                      navigate("/auth/sign-up")
                    }} variant="contained" color="secondary">
                      Sign up
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </>
          }

          <Box m={1}>
            <div style={{
              display: 'flex',
              justifyContent: 'center'
            }}>
              <img style={{
                height: '150px'
              }} src="/stratzord-logo.png" alt="logo stratzord" />
            </div>
          </Box>
          <div className="container">
            <NavLink path={"/workspace/team-builder"} text="Team Builder" />
            <NavLink path={"/workspace/kuroro-wiki"} text="Kuroro Wiki" />

            {/* <NavLink path={"/workspace/community"} text="Community" /> */}
            <NavLink path={"/workspace/play-kuroro-beasts"} text="Play Kuroro Beasts" />
            <NavLink path={"#"} text="About Us" onClick={() => {
              window.open("https://www.stratzord.com", "_blank")
            }} />

            {/* <NavLink path={"/workspace/profile"} text="Profile" /> */}

            <span className="line"></span>
          </div>
        </>
      ) : <MobileAppBar />
    }


  </>
}