
import { configureStore } from '@reduxjs/toolkit';
import { authReducer } from './auth';
import { teamBuilderReducer } from './team-builder';
import { userReducer } from './user';
import { uiReducer } from './ui';
import { kuroroWikiReducer } from './kuroro-wiki';




export const store = configureStore({
  reducer: {
    auth: authReducer,
    teamBuilder: teamBuilderReducer,
    user: userReducer,
    ui: uiReducer,
    kuroroWIki: kuroroWikiReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;