import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IKuroro, IKuroroStats } from '../../types';
import { IKuroroMove } from '../../types/IKuroroMove';
import { IFormError, IFormProps } from '../common';

interface IUpdateTeamSettingsFields {
  teamName: string
  madeItPublic: boolean
}



type IActions = 'edit' | 'combat' | 'list'

export interface IKuroroTeamSlot {
  id?: number
  kuroro?: IKuroro,
  bonusStats: IKuroroStats
  moves: IKuroroMove[]
}

export interface IKuroroTeam {
  id: string
  userNickname: string
  name: string
  slots: IKuroroTeamSlot[]
}


interface ITeamBuilderState {
  selectedTeam: IKuroroTeam
  selectedSlot: IKuroroTeamSlot
  selectedSlotsToPlay: IKuroroTeamSlot[]
  myTeams: IKuroroTeam[]
  action: IActions
  viewSelectedToPlay: boolean
  openTypesChart: boolean
  openNewTeamDialog: boolean
  openUpdateTeamSettings: boolean

  openCreateAccount: boolean
  updateTeamSettingsForm: IFormProps<IUpdateTeamSettingsFields>

}

export const initialTeam: IKuroroTeam = {
  id: 'undefined',
  name: '',
  slots: [],
  userNickname: ''
}

export const initialTeamSlot: IKuroroTeamSlot = {
  bonusStats: {
    atk: 0,
    def: 0,
    hp: 0,
    matk: 0,
    mdef: 0,
    speed: 0,
  },
  kuroro: undefined,
  moves: []
}

const initialState: ITeamBuilderState = {
  myTeams: [],
  selectedSlotsToPlay: [],
  selectedTeam: initialTeam,
  selectedSlot: initialTeamSlot,
  action: 'list',
  viewSelectedToPlay: false,
  openTypesChart: false,
  openNewTeamDialog: false,
  openCreateAccount: false,
  openUpdateTeamSettings: false,
  updateTeamSettingsForm: {
    errors: [],
    fields: {
      madeItPublic: false,
      teamName: ''
    },
    touched: false,
    delivered: false
  }
};

export const validateUpdateTeamSettingsForm = (fields: IUpdateTeamSettingsFields): IFormError<keyof IUpdateTeamSettingsFields>[] => {
  const errors: IFormError<keyof IUpdateTeamSettingsFields>[] = [];
  if (fields.teamName === '' || !fields.teamName) {
    errors.push({
      key: 'teamName',
      message: 'Team name is a required field'
    })
  }

  return errors
}


const teamBuilderSlice = createSlice({
  name: 'teamBuilder',
  initialState,
  reducers: {
    setOpenTypesChartState(state, action: PayloadAction<{
      openTypesChart: boolean
    }>) {
      state.openTypesChart = action.payload.openTypesChart
    },
    setOpenNewTeamDialogState(state, action: PayloadAction<{
      openNewTeamDialog: boolean
    }>) {
      state.openNewTeamDialog = action.payload.openNewTeamDialog
    },
    setOpenCreateAccountState(state, action: PayloadAction<{
      openCreateAccount: boolean
    }>) {
      state.openCreateAccount = action.payload.openCreateAccount
    },
    setOpenUpdateTeamSettingsState(state, action: PayloadAction<{
      open: boolean
    }>) {
      state.openUpdateTeamSettings = action.payload.open
    },
    setViewSelectedToPlayState(state, action: PayloadAction<{
      viewSelectedToPlay: boolean
    }>) {
      state.viewSelectedToPlay = action.payload.viewSelectedToPlay
    },
    setSelectedSlotsToPlayState(state, action: PayloadAction<{
      slotsToPlay: IKuroroTeamSlot[]
    }>) {
      state.selectedSlotsToPlay = action.payload.slotsToPlay
    },
    setSelectedKuroroTeamState(state, action: PayloadAction<{
      kuroroTeam: IKuroroTeam
    }>) {
      state.selectedTeam = action.payload.kuroroTeam
    },
    setSelectedKuroroTeamSlotState(state, action: PayloadAction<{
      kuroroTeamSlot: IKuroroTeamSlot
    }>) {
      state.selectedSlot = action.payload.kuroroTeamSlot
    },
    setMyTeamsState(state, action: PayloadAction<{
      myTeams: IKuroroTeam[]
    }>) {
      state.myTeams = action.payload.myTeams
    },
    addSlot(state) {
      const newSlot = {
        ...initialTeamSlot,
        id: Math.random()
      }
      state.selectedTeam.slots.push(newSlot)

      state.selectedSlot = newSlot
    },
    saveTeamSlot(state, action: PayloadAction<void>) {
      if (state.selectedTeam) {
        if (state.selectedSlot) {
          const indexOf = state.selectedTeam.slots.findIndex((item) => {
            return item.id === state.selectedSlot?.id
          })
          state.selectedTeam.slots[indexOf] = state.selectedSlot
          state.selectedSlot = initialTeamSlot
        }
      }
    },
    setAction(state, action: PayloadAction<{
      action: IActions
    }>) {
      state.action = action.payload.action
    },
    setUpdateTeamSettingsFormName(state, action: PayloadAction<{
      name: string
    }>) {
      state.updateTeamSettingsForm.fields.teamName = action.payload.name
    },
    setUpdateTeamSettingsFormMadeItPublic(state, action: PayloadAction<{
      madeItPublic: boolean
    }>) {
      state.updateTeamSettingsForm.fields.madeItPublic = action.payload.madeItPublic
    },
    setUpdateTeamSettingsFormTouched(state, action: PayloadAction<{
      touched: boolean
    }>) {
      state.updateTeamSettingsForm.touched = action.payload.touched
    },
    setUpdateTeamSettingsFormErrors(state, action: PayloadAction<{
      errors: IFormError<keyof IUpdateTeamSettingsFields>[]
    }>) {
      state.updateTeamSettingsForm.errors = action.payload.errors
    },
  }
});

export const {
  setSelectedKuroroTeamState,
  setMyTeamsState,
  saveTeamSlot,
  setSelectedKuroroTeamSlotState,
  addSlot,
  setAction,
  setSelectedSlotsToPlayState,
  setViewSelectedToPlayState,
  setOpenTypesChartState,
  setOpenNewTeamDialogState,
  setOpenCreateAccountState,
  setUpdateTeamSettingsFormErrors,
  setUpdateTeamSettingsFormName,
  setUpdateTeamSettingsFormMadeItPublic,
  setUpdateTeamSettingsFormTouched,
  setOpenUpdateTeamSettingsState
} = teamBuilderSlice.actions;

export const teamBuilderReducer = teamBuilderSlice.reducer;