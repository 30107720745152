import React, { useEffect, useState } from 'react';

import { Box, Grid, Slider, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../hooks';
import { setSelectedKuroroTeamSlotState } from '../application/team-builder';





export function StatusSection() {
  const [consumedPoints, setConsumedPoints] = useState(0)

  const selectedSlot = useAppSelector((state) => state.teamBuilder.selectedSlot)
  const dispatch = useAppDispatch()

  useEffect(() => {
    let consumedPoints = 0
    for (let stat in selectedSlot.bonusStats) {
      consumedPoints += (selectedSlot.bonusStats as any)[stat];

    }
    setConsumedPoints(consumedPoints)
  }, [selectedSlot])
 
  return <>
    <Grid container wrap="nowrap" justifyContent={"space-between"} alignItems={"center"}>
      <Grid item>
        <Typography fontWeight={"bold"} style={{
          margin: '30px 0'
        }}>
          Available points:
        </Typography>
      </Grid>
      <Grid item>
        <Grid container wrap='nowrap' alignItems={"center"} spacing={1}>
          <Grid item>
            <Typography variant='h5' style={{
              marginLeft: '5px',
            }} color={100 - consumedPoints < 0 ? 'error' : 'primary'} display={"inline-block"} fontWeight={"bold"}>
              {
                100 - consumedPoints
              }
            </Typography>
          </Grid>
          <Grid item>
            {100 - consumedPoints < 0 ? <>
              <Typography color="error">
                (Bonus  stats cannot be greater then 100)
              </Typography>
            </> : ''}
          </Grid>
        </Grid>

      </Grid>

    </Grid>


    <Grid container spacing={3} style={{
      marginTop: '30px'
    }}>
      {
        Object.keys(selectedSlot.bonusStats).map((item) => {
          return <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h5" color="secondary" fontWeight={"bold"}>
                  {item.toUpperCase()}:
                  <Typography style={{
                    marginLeft: '10px'
                  }} display={"inline-block"} color="primary" fontWeight={"bold"}>
                    <Typography color="primary" display={"inline-block"} variant='h6' fontWeight={"bold"}>
                      {
                        (selectedSlot.kuroro?.status as any)[item]
                      }
                    </Typography>
                    <Typography style={{
                      margin: '0 5px'
                    }} color="primary" display={"inline-block"} variant='h6' fontWeight={"bold"}>
                      +
                    </Typography >
                    <Typography style={{
                      color: '#00ade7'
                    }} color="primary" display={"inline-block"} variant='h6' fontWeight={"bold"}>
                      {
                        (selectedSlot.bonusStats as any)[item]
                      }
                    </Typography >

                    <Typography style={{
                      margin: '0 5px'
                    }} color="primary" display={"inline-block"} variant='h6' fontWeight={"bold"}>
                      =
                    </Typography >
                    <Typography color="white" display={"inline-block"} variant='h5' fontWeight={"bold"}>
                      {
                        (selectedSlot.bonusStats as any)[item] + (selectedSlot.kuroro?.status as any)[item]

                      }
                    </Typography >
                  </Typography>
                </Typography>

              </Grid>
              <Grid item xs={12}>
                <Box m={1}>
                  <Slider
                    value={(selectedSlot.bonusStats as any)[item]}
                    onChange={(e, newValue) => {
                      dispatch(setSelectedKuroroTeamSlotState({
                        kuroroTeamSlot: {
                          ...selectedSlot,
                          bonusStats: {
                            ...selectedSlot.bonusStats,
                            [item]: newValue
                          }
                        }
                      }))

                    }}
                    marks={false}
                    max={50}
                    min={0}
                    valueLabelDisplay="off"
                  />
                </Box>
              </Grid>
            </Grid>

          </Grid>
        })
      }
    </Grid >

  </>;
}
