import { useCallback } from "react"
import { useAxiosInstance } from "../axios-instance"
import { IKuroro } from "../../types"
import { IKuroroType } from "../../types/IKuroroType"
import { IKuroroMove, IKuroroMoveCategory } from "../../types/IKuroroMove"


interface IKuroroApiModel {
  "id": string,
  "name": string,
  "hitPoints": number,
  "attack": number,
  "defense": number,
  "magicAttack": number,
  "magicDefense": number,
  "speed": number,
  "lore": string
  "types": {
    "typeId": number,
    "typeName": string
  }[]
}


interface IMoveApiModel {
  "id": number,
  "name": string,
  "power": number,
  "description": string,
  "category": string,
  "types": {
    "typeId": number,
    "typeName": string
  }[]
}

export const useKuroroWikiApi = () => {
  const { axiosPublicInstance } = useAxiosInstance()

  const getKurorosApi = useCallback(async () => {
    try {
      const response = await axiosPublicInstance.get<IKuroroApiModel[]>("/kuroro")
      const result: IKuroro[] = []
      for (let i = 0; i < response.data.length; i++) {
        const kuroro = response.data[i]
        result.push({
          id: kuroro.id.toString(),
          lore: kuroro.lore,
          name: kuroro.name,
          status: {
            atk: kuroro.attack,
            def: kuroro.defense,
            hp: kuroro.hitPoints,
            matk: kuroro.magicAttack,
            mdef: kuroro.magicDefense,
            speed: kuroro.speed
          },
          types: kuroro.types.map(type => type.typeName as IKuroroType)
        })
      }

      return result
    } catch (err) {
      throw err
    }
  }, [
    axiosPublicInstance
  ])


  const getMovesApi = useCallback(async () => {
    try {
      const result: IKuroroMove[] = []
      const response = await axiosPublicInstance.get<IMoveApiModel[]>('/move')
      for(let i = 0; i < response.data.length; i++){
        const move = response.data[i]
        const type = move.types[0].typeName
        result.push({
          id: move.id.toString(),
          category: move.category as IKuroroMoveCategory,
          description: move.description,
          move: move.name,
          power: move.power,
          type: type  as IKuroroType
        })
      }
      return result
    } catch (err) {
      throw err
    }
  }, [
    axiosPublicInstance
  ])

  return {
    getKurorosApi,
    getMovesApi
  }
}